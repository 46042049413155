import CachedIcon from '@mui/icons-material/Cached';
import { Box, Card, CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { overallConversionRateAction } from '../../redux/home/overallConversionRate';

export default function OverAllConversionRate() {
    // state
    const dispatch = useDispatch()
    const overAllConvresionRateSelector = useSelector(state => state.overAllConvresionRate)
    const { data, loading } = overAllConvresionRateSelector




    // fn
    const handleRefeash = () => {
        dispatch(overallConversionRateAction())
    }

    // use effect
    useEffect(() => {
        dispatch(overallConversionRateAction())
    }, [dispatch])

    return (
        <Card sx={{ p: 2, backgroundImage: 'linear-gradient(to bottom, #7743DB  , #3B3486)' }} >
            <Stack direction='row' justifyContent='space-between' >
                <Typography sx={{ color: '#fff' }} variant='subtitle2'>Over All Conversion Rate</Typography>
                <IconButton sx={{ color: '#fff' }} onClick={handleRefeash}><CachedIcon /></IconButton>
            </Stack>

            {
                loading
                    ? <CircularProgress />
                    : <Box>
                        <Typography variant='h3' color="primary" sx={{ fontWeight: 600, color: '#fff' }}>{data || "--"}</Typography>

                    </Box>
            }
        </Card>
    )
}
