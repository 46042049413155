import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AddIcon from "@mui/icons-material/Add";
import BlockIcon from "@mui/icons-material/Block";
import CachedIcon from "@mui/icons-material/Cached";
import { MdVerified } from "react-icons/md";
import EditIcon from "@mui/icons-material/Edit";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Breadcrumbs,
  Button,
  Card,
  CardActionArea,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Link,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  createSearchParams,
  Link as LinkRRD,
  useNavigate,
} from "react-router-dom";
import LoadingUI from "../../common/LoadingUI";
import NoDataFound from "../../common/NoDataFound";
import { getBankDetailsAction } from "../../redux/payment/getBankDetails";
import { resentPaymentAction } from "../../redux/payment/resentPayment";
import BodyWaperUI from "../../ui/BodyWaperUI";
import NavbarUI from "../../ui/NavbarUI";
import SidebarUI from "../../ui/SidebarUI";
import RemoveRestorebankDetails from "./RemoveRestorebankDetails";
import { saveAs } from "file-saver";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { MdDoDisturbAlt } from "react-icons/md";
let bank_id = null;
let is_deleted = null;

const breadcrumb = (
  <Stack spacing={2}>
    <Breadcrumbs
      separator={<NavigateNextIcon sx={{ color: "#fff" }} fontSize="small" />}
      aria-label="breadcrumb"
    >
      <Link underline="hover" sx={{ color: "#fff" }} color="inherit" href="/">
        Home
      </Link>
      <Link
        underline="hover"
        sx={{ color: "#fff" }}
        color="inherit"
        href="/payment"
      >
        Payments
      </Link>
    </Breadcrumbs>
  </Stack>
);

export default function Payment() {
  // state
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getProfileSelector = useSelector((state) => state.getProfile);
  const { data, loading } = getProfileSelector;
  const resentPayment = useSelector((state) => state.resentPayment);
  const { data: resentPaymentData, loading: resentPaymentLoading } =
    resentPayment;

  const getBankDetailsSelector = useSelector((state) => state.getBankDetails);
  const { data: getBankDetailsData } = getBankDetailsSelector;

  const [pageSize, setPageSize] = useState(10);
  const [removeRestoreBankDetails, setRemoveRestoreBankDetails] =
    useState(false);
  const [showBank, setShowBank] = useState(false);

  const RequestPaymentColumn = [
    {
      field: "S. no.",
      headerName: "S no",
      filterable: false,
      flex: 1,
      minWidth: 50,
      headerClassName: "super-app-theme--header",
      renderCell: (index) => index?.api?.getRowIndex(index.row._id) + 1,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      minWidth: 50,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 50,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "invoice",
      headerName: "Download Invoice",
      flex: 1,
      minWidth: 50,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <>
            <Stack sx={{ width: "40%" }} direction="row" spacing={2}>
              <img width={"100%"} src={params?.row?.invoice} />
              {params?.row?.status == "credited" && (
                <IconButton onClick={() => downloadImage(params?.row.invoice)}>
                  <CloudDownloadIcon color="primary" />
                </IconButton>
              )}
            </Stack>
          </>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Date",
      flex: 1,
      minWidth: 50,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        const { row } = params;
        const dateToShow =
          row.status === "approved"
            ? row.approved_at
            : row.status === "credited"
            ? row.credit_at
            : row.created_at;
      
        return (
          <Typography>
            {dateToShow ?? 'No Date Available'}
          </Typography>
        );
      },
    },
  ];

  // fn
  const handleRefresh = () => {};

  const downloadImage = (image) => {
    saveAs(image, "image.jpg");
  };

  // useEffect
  useEffect(() => {
    dispatch(getBankDetailsAction());
    dispatch(resentPaymentAction());
  }, [dispatch]);

  const showVerifyBankPop = () => {
    setShowBank(true);
  };

  console.log(resentPaymentData);

  return (
    <>
      <Helmet>
        <title>PSC | payments</title>
      </Helmet>

      <NavbarUI breadcrumb={breadcrumb} />
      <SidebarUI />
      <BodyWaperUI>
        <Stack sx={{ p: 2 }} direction={"row"} justifyContent={"space-between"}>
          <Stack>
            <Typography variant="h6" color="primary">
              General Information
            </Typography>
            <Typography variant="subtitle2">
              <span style={{ color: "#2092E3" }}> Email </span>:{" "}
              {data?.publisher_email}
            </Typography>
            <Typography variant="subtitle2">
              <span style={{ color: "#2092E3" }}> Address </span>
              {data?.address?.street_no},{data?.address?.street_name},
              {data?.address?.city},{data?.address?.city},
              {data?.address?.country}
            </Typography>
          </Stack>
          <Stack>
            <Card
              sx={{
                paddingInline: "20px",
                paddingBlock: "10px",
              }}
            >
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#2092E3",
                }}
              >
                Available Balance
              </Typography>
              <Typography
                style={{
                  fontWeight: "bold",
                  opacity: 0.8,
                }}
              >
                {Number(data?.available_amount).toFixed(2)} ₹
              </Typography>
            </Card>
          </Stack>
        </Stack>

        {/* {bank_transter_select */}
        {/* ? <> */}
        <Stack sx={{ mt: 3 }}>
          <Card>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems={"center"}
              sx={{ p: 2 }}
            >
              <Typography variant="h6" color="primary">
                Recent Payments
              </Typography>
              <IconButton onClick={handleRefresh}>
                <CachedIcon />
              </IconButton>
            </Stack>
            <Paper
              sx={{
                bgcolor: "#fff",
                "& .super-app-theme--header": {
                  bgcolor: "primary.main",
                  color: "#fff",
                },
              }}
              elevation={0}
            >
              <DataGrid
                autoHeight={true}
                sx={{ border: 0 }}
                rows={resentPaymentData}
                columns={RequestPaymentColumn}
                getRowId={(row) => row._id}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                pagination
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 25, 50, 100]}
                disableColumnSelector
                loading={resentPaymentLoading ? <LoadingUI /> : false}
                components={{
                  NoRowsOverlay: () => <NoDataFound />,
                  // Toolbar: GridToolbar,
                }}
              />
            </Paper>
          </Card>
        </Stack>

        <Grid container sx={{ mt: 2 }} columnGap={2}>
          <Grid items xs={3}>
            <Card>
              <CardActionArea sx={{ p: 2 }}>
                <LinkRRD
                  to={
                    Array.isArray(getBankDetailsData) &&
                    getBankDetailsData.length > 0
                      ? ""
                      : "/payment/add-details"
                  }
                  style={{ textDecoration: "none" }}
                >
                  <Stack alignItems="center" justifyContent={"space-between"}>
                    <Typography variant="subtitle1" color="primary">
                      Add Bank Details
                    </Typography>
                    {Array.isArray(getBankDetailsData) &&
                    getBankDetailsData.length > 0 ? (
                      <MdDoDisturbAlt
                        style={{
                          fontSize: "2rem",
                          margin: "10px",
                        }}
                      />
                    ) : (
                      <IconButton>
                        <AddIcon fontSize="large" />
                      </IconButton>
                    )}
                  </Stack>
                </LinkRRD>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid items xs={3}>
            <Card>
              <CardActionArea sx={{ p: 2 }}>
                <LinkRRD
                  to={
                    Array.isArray(getBankDetailsData) &&
                    getBankDetailsData[0]?.is_varified &&
                    "/payment/request-widthdraw"
                  }
                  onClick={
                    Array.isArray(getBankDetailsData) &&
                    !getBankDetailsData[0]?.is_varified &&
                    showVerifyBankPop
                  }
                  style={{ textDecoration: "none" }}
                >
                  <Stack alignItems="center" justifyContent={"space-between"}>
                    <Typography variant="subtitle1" color="primary">
                      Request Withdraw Amount
                    </Typography>
                    <IconButton>
                      <AddIcon fontSize="large" />
                    </IconButton>
                  </Stack>
                </LinkRRD>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>

        <Card sx={{ p: 2, mt: 2 }}>
          <Typography sx={{ my: 2 }} variant="h6" color="primary">
            Payment Method
          </Typography>
          {getBankDetailsData?.map((items, index) => {
            return (
              <Box key={index} sx={{ mt: 1 }}>
                <Stack>
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    justifyContent={"space-between"}
                  >
                    <Stack direction={"row"} alignItems="center">
                      <AccountBalanceIcon fontSize="large" />
                      <Stack>
                        <Typography sx={{ ml: 2 }} variant="subtitle2">
                          Bank transfer
                        </Typography>
                        {loading ? (
                          <LoadingUI />
                        ) : (
                          <Typography
                            sx={{ ml: 2 }}
                            color="primary"
                            variant="h6"
                          >
                            {items?.branch}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                    <Box>
                      <IconButton
                        disabled={items?.is_deleted}
                        onClick={() => {
                          navigate({
                            pathname: "/payment/edit-details",
                            search: createSearchParams({
                              bank_id: items?._id,
                            }).toString(),
                          });
                          window.location.reload();
                        }}
                      >
                        <EditIcon color="primary" />
                      </IconButton>

                      {items?.is_deleted ? (
                        <IconButton
                          onClick={() => {
                            setRemoveRestoreBankDetails(true);
                            bank_id = items?._id;
                            is_deleted = false;
                          }}
                        >
                          <BlockIcon color="error" />
                        </IconButton>
                      ) : (
                        <MdVerified
                          // onClick={
                          //   Array.isArray(getBankDetailsData) &&
                          //   getBankDetailsData[0]?.is_varified
                          //     ? ""
                          //     : () => {
                          //         setRemoveRestoreBankDetails(true);
                          //         bank_id = items?._id;
                          //         is_deleted = true;
                          //       }
                          // }
                          style={{
                            fontSize: "2rem",
                            position: "relative",
                            top: "8px",

                            color:
                              Array.isArray(getBankDetailsData) &&
                              getBankDetailsData[0]?.is_varified
                                ? "green"
                                : "",
                          }}
                        />
                      )}
                    </Box>
                  </Stack>
                </Stack>
              </Box>
            );
          })}
        </Card>
      </BodyWaperUI>
      <Dialog open={showBank} onClose={() => setShowBank(false)}>
        <DialogContent>
          <Typography>
            Your bank account is not verified, please wait untill your bank
            accounts get verified
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant={"contained"} onClick={() => setShowBank(false)}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <RemoveRestorebankDetails
        state={removeRestoreBankDetails}
        setState={setRemoveRestoreBankDetails}
        bank_id={bank_id}
        is_deleted={is_deleted}
      />
    </>
  );
}
