import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Link, Paper, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import uniqid from "uniqid";
import { useDispatch, useSelector } from "react-redux";

import BodyWaperUI from "../../ui/BodyWaperUI";
import NavbarUI from "../../ui/NavbarUI";
import SidebarUI from "../../ui/SidebarUI";
import { getPostbaclLogsAction } from "../../redux/postback/postback_logs";
import { DataGrid } from "@mui/x-data-grid";

const breadcrumb = (
  <Stack spacing={2}>
    <Breadcrumbs
      separator={<NavigateNextIcon sx={{ color: "#fff" }} fontSize="small" />}
      aria-label="breadcrumb"
    >
      <Link underline="hover" sx={{ color: "#fff" }} color="inherit" href="/">
        Home
      </Link>
      <Link
        underline="hover"
        sx={{ color: "#fff" }}
        color="inherit"
        href="/postback-logs"
      >
        Postback Logs
      </Link>
    </Breadcrumbs>
  </Stack>
);

export default function PostBackLogs() {
  // state

  const getProfileSelector = useSelector((state) => state.PostbackLogs);
  const { data } = getProfileSelector;

  const dispatch = useDispatch();

  const column = [
    {
      field: "publisherName",
      headerName: "Publisher Name",
      filterable: false,

      minWidth: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "status",
      headerName: "Status",
      filterable: false,
  
      minWidth: 50,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "time",
      headerName: "Time",
      filterable: false,
 
      minWidth: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "userName",
      headerName: "User Name",
      filterable: false,
  
      minWidth: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "amount",
      headerName: "Amount",
      filterable: false,

      minWidth: 50,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "url",
      headerName: "URL",
      filterable: false,
      
      minWidth: 1200,
      headerClassName: "super-app-theme--header",
    },
    
  ];

  // useEffect
  useEffect(() => {
    dispatch(getPostbaclLogsAction());
  }, []);
console.log(data)
  return (
    <>
      <Helmet>
        <title>PSC | postback</title>
      </Helmet>

      <NavbarUI breadcrumb={breadcrumb} />
      <SidebarUI />
      <BodyWaperUI>
        <Paper
          sx={{
            bgcolor: "#fff",
            "& .super-app-theme--header": {
              bgcolor: "primary.main",
              color: "#fff",
            },
          }}
          elevation={0}
        >
          <DataGrid rows={data} columns={column} getRowId={uniqid} autoHeight />
        </Paper>
      </BodyWaperUI>

      {/* <Hash state={hashModal} setState={sethashModal} /> */}
    </>
  );
}
