import { Box } from '@mui/system'
import React from 'react'

export default function Settings() {
    return (
        <Box>

        </Box>
    )
}
