import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Breadcrumbs, Grid, Link, Stack } from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet'
import BodyWaperUI from '../../ui/BodyWaperUI'
import NavbarUI from '../../ui/NavbarUI'
import SidebarUI from '../../ui/SidebarUI'
import AddPublisher from '../publisher/AddPublisher'
import AddSocialMeida from './AddSocialMeida'
// import SocialMedia from './SocialMedia'


const breadcrumb = (
    <Stack spacing={2}>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#fff' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: '#fff' }} color='inherit' href='/'>Home</Link>
            <Link underline="hover" sx={{ color: '#fff' }} color='inherit' href='/profile'>Profile</Link>
        </Breadcrumbs>
    </Stack >
)


export default function Profile() {
    return (
        <>
            <Helmet>
                <title>PSC | profile</title>
            </Helmet>

            <NavbarUI breadcrumb={breadcrumb} />
            <SidebarUI />
            <BodyWaperUI>


                <Grid container>
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        {/* platform settings */}
                        <Grid item xs={12}>
                            <AddPublisher />
                        </Grid>
                        <Grid item xs={12}>
                            <AddSocialMeida />
                        </Grid>
                    </Grid>

                </Grid>

            </BodyWaperUI>
        </>
    )
}
