import PropTypes from 'prop-types';
import React from "react";
import { Navigate } from "react-router-dom";

function ProtectedRoute({ children }) {

    // console.log("varified?.registeration", varified?.registeration)

    const token = JSON.parse(localStorage.getItem("ps_token"))
    const reg_verify = JSON.parse(localStorage.getItem("ps_reg_verify"))

    if (token && reg_verify) {
        return children
    }
    else if (!token) {
        return <Navigate to="/login" />
    }
    else if (token && !reg_verify) {
        return <Navigate to="/new-registration-verify" />
    }

}

export default ProtectedRoute;


ProtectedRoute.propTypes = {
    children: PropTypes.any,
};