import VerifiedIcon from '@mui/icons-material/Verified'
import { Box, Card, CircularProgress, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types';

export default function NoOfQualifiedSurvey({ data, loading }) {

    return (
        <Grid item xs={4}>
            <Card sx={{ p: 2, backgroundImage: 'linear-gradient(to bottom, #6dd5ed,  #2092E3)' }} >
                <Stack spacing={2} alignItems={'center'}>
                    <Box sx={{ width: 70, height: 70, borderRadius: 1, background: "#2092E3", color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <VerifiedIcon sx={{ fontSize: 50 }} />
                    </Box>
                    <Typography sx={{ color: '#fff' }} variant='subtitle2'>No of Qualified Survey</Typography>
                    {loading
                        ? <CircularProgress />
                        : <Typography variant='h3' sx={{ fontWeight: 600, color: '#fff' }}>{data || "--"}</Typography>}

                </Stack>
            </Card>
        </Grid >
    )
}

NoOfQualifiedSurvey.propTypes = {
    data: PropTypes.any,
    loading: PropTypes.any,
};
