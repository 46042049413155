import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "../../config/authAxios"



const initialState = { status: null, loading: false, data: [], error: "", message: "" }


export const getSingleBankDetailsAction = createAsyncThunk(
    'get-single-bank-details',
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.get(`/publisher/api/get-single-bank-details/${data}`)
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const getSingleBankDetailsSlice = createSlice({
    name: 'get-single-bank-details',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(getSingleBankDetailsAction.pending, (state) => {
                state.loading = true
            })
            builder.addCase(getSingleBankDetailsAction.fulfilled, (state, action) => {
                state.loading = false
                state.data = action?.payload?.data
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
            builder.addCase(getSingleBankDetailsAction.rejected, (state, action) => {
                state.loading = false
                state.error = action?.payload?.error
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
        },

})



export const getSingleBankDetailsReducer = getSingleBankDetailsSlice.reducer