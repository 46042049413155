import CachedIcon from '@mui/icons-material/Cached'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Box, Breadcrumbs, Card, CircularProgress, Grid, IconButton, Link, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { default as React } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { getProfileAction } from '../../redux/profile/getPublisherProfile'
import BodyWaperUI from '../../ui/BodyWaperUI'
import NavbarUI from '../../ui/NavbarUI'
import SidebarUI from '../../ui/SidebarUI'

const breadcrumb = (
    <Stack spacing={2} >
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" sx={{ color: '#fff' }} />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: '#fff' }} color='inherit' href='/account-manager-details'>Account Manager Details</Link>
        </Breadcrumbs>
    </Stack>
)

export default function AccountManegerDetails() {
    const dispatch = useDispatch()
    // state
    const getProfileSelector = useSelector(state => state.getProfile)
    const { data, loading } = getProfileSelector


    const handleRefresh = () => {
        dispatch(getProfileAction())
    }

    return (
        <>
            <Helmet>
                <title>PSC | Support & Knowledge</title>
            </Helmet>


            <NavbarUI breadcrumb={breadcrumb} />
            <SidebarUI />
            <BodyWaperUI>
                <Card sx={{ p: 2, height: 'auto' }}>
                    <Stack direction='row' justifyContent='space-between' >
                        <Typography variant='h5'>Revenue</Typography>
                        <IconButton onClick={handleRefresh}><CachedIcon /></IconButton>
                    </Stack>
                    <Grid container spacing={2}>
                        <Grid xs={4} item>
                            <Box sx={{ my: 2 }}>
                                {loading
                                    ? <CircularProgress />
                                    : <Stack spacing={2}>
                                        <Stack sx={{ mt: 1 }}>
                                            <Typography variant='h6'>Maneger Name</Typography>
                                            <Typography variant='h5' sx={{ fontWeight: 600 }} color='primary'>{data?.m_name || "--"}</Typography>
                                        </Stack>
                                        <Stack sx={{ mt: 1 }}>
                                            <Typography variant='h6'>Maneger Email</Typography>
                                            <Typography variant='h5' sx={{ fontWeight: 600 }} color='primary'>{data?.m_email || "--"}</Typography>
                                        </Stack>
                                        <Stack sx={{ mt: 1 }}>
                                            <Typography variant='h6'>Maneger Mobile Number</Typography>
                                            <Typography variant='h5' sx={{ fontWeight: 600 }} color='primary'>{data?.m_mobile || "--"}</Typography>
                                        </Stack>
                                    </Stack>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Card >
            </BodyWaperUI>
        </>
    )
}
