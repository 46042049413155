import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Card, Stack, Typography } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function NewRegistrationVerification() {

    // state
    const navigate = useNavigate()
    const getProfileSelector = useSelector(state => state.getProfile)
    const { data } = getProfileSelector

    useEffect(() => {
        if (data?.varified?.registeration) {
            console.log("varified useEffect")
            localStorage.setItem('ps_reg_verify', JSON.stringify(data?.varified?.registeration));
            navigate("/")
        }
    }, [data])


    return (
        <Stack sx={{ width: '100vw', height: '100vh' }} alignItems='center' justifyContent={'center'}>
            <Card sx={{ p: 4 }}>
                <Stack spacing={2} alignItems={'center'}>
                    <CheckCircleIcon sx={{ width: 80, height: 80 }} color='success' />
                    <Typography variant='h4'>Registration Successful</Typography>
                    <Stack spacing={.5} justifyContent='center' sx={{ width: '60%' }}>
                        <Typography variant='subtitle2' textAlign={"center"}>Thank you for registering on Quick surveys.
                            Please give us some time to verify your details.
                            Our support team will reach out to you as soon as possible.
                        </Typography>
                    </Stack>
                </Stack>
            </Card >
        </Stack >
    )
}
