import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "../../config/authAxios"



const initialState = { status: null, loading: false, data: [], error: "", message: "" }


export const overallConversionRateAction = createAsyncThunk(
    'over-all-conversion-rate',
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.get(`/publisher/api/over-all-conversion-rate`)
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const overallConversionRateSlice = createSlice({
    name: 'over-all-conversion-rate',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(overallConversionRateAction.pending, (state) => {
                state.loading = true
            })
            builder.addCase(overallConversionRateAction.fulfilled, (state, action) => {
                state.loading = false
                state.data = action?.payload?.data
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
            builder.addCase(overallConversionRateAction.rejected, (state, action) => {
                state.loading = false
                state.error = action?.payload?.error
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
        },
})


export const overallConversionRateReducer = overallConversionRateSlice.reducer