import { yupResolver } from "@hookform/resolvers/yup";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Link,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Link as LinkRRD,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import * as yup from "yup";
import LoadingUI from "../../common/LoadingUI";
import { editBankDetailsAction } from "../../redux/payment/editBankDetails";
import { getSingleBankDetailsAction } from "../../redux/payment/getSingleBankDetails";
import BodyWaperUI from "../../ui/BodyWaperUI";
import NavbarUI from "../../ui/NavbarUI";
import SidebarUI from "../../ui/SidebarUI";
import SnackUI from "../../common/SnackUI";

const breadcrumb = (
  <Stack spacing={2}>
    <Breadcrumbs
      separator={<NavigateNextIcon sx={{ color: "#fff" }} fontSize="small" />}
      aria-label="breadcrumb"
    >
      <Link underline="hover" sx={{ color: "#fff" }} color="inherit" href="/">
        Home
      </Link>
      <Link
        underline="hover"
        sx={{ color: "#fff" }}
        color="inherit"
        href="/payments"
      >
        Payments
      </Link>
      <Link
        underline="hover"
        sx={{ color: "#fff" }}
        color="inherit"
        href="/payment/edit-details"
      >
        Bank details
      </Link>
    </Breadcrumbs>
  </Stack>
);

const paymentDetailsSchema = yup
  .object({
    holder_name: yup.string().required("Holder name is required."),
    account_no: yup
      .number()
      .positive()
      .required("Account no  is required.")
      .typeError("Please enter a valid account number"),
    ifcs_code: yup.string().required("IFSC code is required."),
    branch: yup.string().required("Branch is required."),

    account_type: yup
      .string()
      .required("Account type is required.")
      .typeError("Account type is Required"),
    bussiness_name: yup.string().required("Bussiness name is required."),
    country: yup.string().required("Country is required."),
    address: yup.string().required("Address is required."),
    address2: yup.string().required("Address2 is required."),
    city: yup.string().required("City is required."),
    region: yup.string().required("Region is required."),
    postal_code: yup
      .number()
      .positive()
      .required()
      .typeError("Please enter a valid postal code"),
  })
  .required();

export default function EditPaymentDetails() {
  // state
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searcbParams] = useSearchParams();
  const bank_id = searcbParams.get("bank_id");
  const [postalCode, setPostalCode] = useState("");

  const getSingleBankDetails = useSelector(
    (state) => state.getSingleBankDetails
  );
  const { data, loading } = getSingleBankDetails;
  const { loading:editLoading,message:editMessage,status:editStatus } = useSelector(state=>state.editBankDetails)
  const[snake,setSnake] = useState(false)

  const {
    register,
    reset,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(paymentDetailsSchema),
    defaultValues: {},
  });

  // fn
  const handleBankDetails = async (data) => {
    if (postalCode.toString().length !== 6) {
      return setError("postal_code", {
        type: "custom",
        message: "Please enter a valid postal code",
      });
    }
    let bankDetails = await dispatch(
      editBankDetailsAction({ bank_id: bank_id, ...data })
    );
    setSnake(true)
    if (bankDetails?.payload?.status == 200) {
        setTimeout(()=>{   navigate("/payment")},800)
   
    }
  };
 
  // useEffect
  useEffect(() => {
    let defaultValues = {};
    defaultValues.account_no = data?.account_no;
    defaultValues.branch = data?.branch;
    defaultValues.holder_name = data?.holder_name;
    defaultValues.ifcs_code = data?.ifcs_code;
    defaultValues.bussiness_name = data?.bussiness_name;
    defaultValues.account_type = data?.account_type;
    defaultValues.country = data?.country;
    defaultValues.address = data?.address;
    defaultValues.address2 = data?.address2;
    defaultValues.city = data?.city;
    defaultValues.region = data?.region;
    defaultValues.postal_code = data?.postal_code;
    setPostalCode(data?.postal_code)
    reset(defaultValues);
  }, [data]);

  useEffect(() => {
    dispatch(getSingleBankDetailsAction(bank_id));
  }, [dispatch]);
 console.log(postalCode)
  return (
    <>
      <Helmet>
        <title>PSC | Add Payments details</title>
      </Helmet>

      <NavbarUI breadcrumb={breadcrumb} />
      <SidebarUI />
      <BodyWaperUI>
        {loading ? (
          <LoadingUI />
        ) : (
          <form onSubmit={handleSubmit(handleBankDetails)}>
            <Card sx={{ p: 2 }}>
              <Typography variant="h6" sx={{ my: 2 }}>
                Add Bank Account Details
              </Typography>

              <Stack spacing={2}>
                <TextField
                  {...register("account_no")}
                  defaultValue={data?.account_no}
                  helperText={errors?.account_no?.message}
                  error={errors?.account_no?.message}
                  label="Back Account No."
                  placeholder="Enter Bank Account"
                />
                <TextField
                  {...register("holder_name")}
                  defaultValue={data?.holder_name}
                  helperText={errors?.holder_name?.message}
                  error={errors?.holder_name?.message}
                  label={`Account Holder's Name`}
                  placeholder="Enter Account Holder Name"
                />
                <TextField
                  {...register("ifcs_code")}
                  defaultValue={data?.ifcs_code}
                  helperText={errors?.ifcs_code?.message}
                  error={errors?.ifcs_code?.message}
                  label="IFSC Code"
                  placeholder="Enter IFSC Code"
                />
                <TextField
                  {...register("branch")}
                  defaultValue={data?.branch}
                  helperText={errors?.branch?.message}
                  error={errors?.branch?.message}
                  label="Branch"
                  placeholder="Enter Branch"
                />

                {data?.account_type && (
                  <FormControl>
                    <FormLabel>Account Type</FormLabel>
                    <RadioGroup defaultValue={data?.account_type}>
                      <FormControlLabel
                        {...register("account_type")}
                        value="Individual"
                        control={<Radio />}
                        label="Individual"
                      />
                      <FormControlLabel
                        {...register("account_type")}
                        value="Business"
                        control={<Radio />}
                        label="Business"
                      />
                    </RadioGroup>
                  </FormControl>
                )}

                <TextField
                  {...register("bussiness_name")}
                  defaultValue={data?.bussiness_name}
                  helperText={errors?.bussiness_name?.message}
                  error={errors?.bussiness_name?.message}
                  label="Bussiness Name"
                  placeholder="Bussiness Name"
                />
                <TextField
                  {...register("country")}
                  defaultValue={data?.country}
                  helperText={errors?.country?.message}
                  error={errors?.country?.message}
                  label="Country"
                  placeholder="Country"
                />
                <TextField
                  {...register("address")}
                  defaultValue={data?.address}
                  helperText={errors?.address?.message}
                  error={errors?.address?.message}
                  label="Address Line"
                  placeholder="Address Line"
                />
                <TextField
                  {...register("address2")}
                  defaultValue={data?.address2}
                  helperText={errors?.address2?.message}
                  error={errors?.address2?.message}
                  label="Address Line 2"
                  placeholder="Address Line 2"
                />
                <TextField
                  {...register("city")}
                  defaultValue={data?.city}
                  helperText={errors?.city?.message}
                  error={errors?.city?.message}
                  label="City"
                  placeholder="City"
                />
                <TextField
                  {...register("region")}
                  defaultValue={data?.region}
                  helperText={errors?.region?.message}
                  error={errors?.region?.message}
                  label="Region"
                  placeholder="Region"
                />
                <TextField
                  {...register("postal_code")}
                  onChange={(e) => setPostalCode(e.target.value)}
                  defaultValue={data?.postal_code}
                  helperText={errors?.postal_code?.message}
                  error={errors?.postal_code?.message}
                  label="Postal Code"
                  placeholder="Postal Code"
                />

                <Box>
                  <Button type="submit" variant="contained">
                  Add Bank Details
                  </Button>
                  <LinkRRD style={{ textDecoration: "none" }} to="/payment">
                    <Button sx={{ ml: 2 }} variant="contained" color="error">
                      Back
                    </Button>
                  </LinkRRD>
                </Box>
              </Stack>
            </Card>
          </form>
        )}
<Dialog open = {editLoading} >
    <DialogContent>
        <Stack justifyContent={"center"}>
        <LoadingUI/>
        </Stack>
   
    </DialogContent>

</Dialog>
      </BodyWaperUI>
      <SnackUI message={editMessage} setState={setSnake} state={snake} status={editStatus}/>
    </>
  );
}
