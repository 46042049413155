import { Box, Card, CircularProgress, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CachedIcon from '@mui/icons-material/Cached';
import { totalUsersAction } from '../../redux/home/totalUsers';

export default function TotalUser() {
    // state
    const dispatch = useDispatch()
    const totalUsersSelector = useSelector(state => state.totalUsers)
    const { data, loading } = totalUsersSelector



    // fn
    const handleRefeash = () => {
        dispatch(totalUsersAction())
    }

    // use effect
    useEffect(() => {
        dispatch(totalUsersAction())
    }, [dispatch])

    return (
        <Card sx={{ p: 2, backgroundImage: 'linear-gradient(to bottom, #EA047E,  #D61355)' }} >
            <Stack direction='row' justifyContent='space-between' >
                <Typography sx={{ color: '#fff' }} variant='subtitle2'>Total Registered Users</Typography>
                <IconButton sx={{ color: '#fff' }} onClick={handleRefeash}><CachedIcon /></IconButton>
            </Stack>

            {
                loading
                    ? <CircularProgress />
                    : <Box>
                        <Typography variant='h3' color='primary' sx={{ fontWeight: 600, color: '#fff' }}>{data || "--"}</Typography>
                    </Box>
            }
        </Card>
    )
}
