import { yupResolver } from '@hookform/resolvers/yup'
import InfoIcon from '@mui/icons-material/Info'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Box, Breadcrumbs, Button, CircularProgress, IconButton, Link, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as yup from "yup"
import SnackUI from '../../common/SnackUI'
// import { getDashboardFullSupportAction } from '../../redux/auth/getDashbaordFullSupport'
import { addpostbackUrlAction } from '../../redux/postback/addpostbackUrl'
import BodyWaperUI from '../../ui/BodyWaperUI'
import NavbarUI from '../../ui/NavbarUI'
import SidebarUI from '../../ui/SidebarUI'

const breadcrumb = (
    <Stack spacing={2}>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#fff' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: '#fff' }} color='inherit' href='/'>Home</Link>
            <Link underline="hover" sx={{ color: '#fff' }} color='inherit' href='/postback'>Postback</Link>
        </Breadcrumbs>
    </Stack>
)


// login schema
const addpostbackUrl = yup.object({
    postback_url: yup.string().required(),
}).required();


export default function PostBack() {
    // state 
    const { register, reset, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(addpostbackUrl), defaultValues: {} });

    const addpostbackUrlSelector = useSelector(state => state.addpostbackUrl)
    const { status, message, loading } = addpostbackUrlSelector
    const getProfileSelector = useSelector(state => state.getProfile)
    const { data: profileData } = getProfileSelector





    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [snack, setSnack] = useState(false)
    // const [hashModal, sethashModal] = useState(false)

    // fn
    const postbackUrlHandle = async (data) => {

        const postbackData = await dispatch(addpostbackUrlAction(data))
        if (postbackData.payload?.status == 200) {
            // await dispatch(getDashboardFullSupportAction())
            // sethashModal(true)
            setSnack(true)
        }
    }


    // useEffect
    useEffect(() => {
        let defaultValues = {}
        defaultValues.postback_url = profileData?.postback_url
        reset(defaultValues)
    }, [profileData])

    return (
        <>

            <Helmet>
                <title>PSC | postback</title>
            </Helmet>

            <NavbarUI breadcrumb={breadcrumb} />
            <SidebarUI />
            <BodyWaperUI>

                <form onSubmit={handleSubmit(postbackUrlHandle)}>
                    <Stack spacing={1}>
                        <Typography variant='h6'>Add Postback here</Typography>
                        <Stack direction={'row'} alignItems={'center'}>
                            <Typography variant='subtitle2'>You can add postback from here, if you are facing any difficulty to generate a postback, please go to document section and do necessary steps.</Typography>
                            <IconButton onClick={() => navigate('/doc?postback')}><InfoIcon color='warning' /></IconButton>
                        </Stack>

                        <Box>
                            <TextField fullWidth sx={{ flexGrow: 1 }} defaultValue={profileData?.postback_url} variant="outlined"  {...register('postback_url')} helperText={errors?.postback_url?.message} error={errors?.postback_url?.message} />
                            <Button sx={{ my: 2 }} disabled={loading} variant='contained' type='submit'>{loading ? <CircularProgress /> :profileData?.postback_url ? 'Edit Postback' :'Add Post Back'}</Button>
                            {/* <Box sx={{ my: 2 }}>
                                <Typography variant='h6' color='error'>Important points</Typography>
                                <Stack>
                                    <Typography variant='subtitle2' color='error'>* platfrom is required to send to your survey app !</Typography>
                                </Stack>
                            </Box> */}

                            <Typography variant='subtitle2'>
                                <strong>Postback URL example : &nbsp;</strong>
                                <span style={{ color: 'red' }}>{`${profileData?.postback_url}?`}</span>{`platfrom={platfrom}&{total_sale = total_sale}&{last_month_sale = last_month_sale}&{all_trafic = all_trafic}&{total_users = total_users}&{total_survey_complete = total_survey_complete}&{number_of_publisher = number_of_publisher}&{resent_activity = resent_activity}&{other_status = other_status}&{no_of_survey_attempts = no_of_survey_attempts}&{no_of_participent = no_of_participent}&{no_of_quailfied_survey = no_of_quailfied_survey}&{no_of_survey_droped_by_user = no_of_survey_droped_by_user}&{no_of_survey_imperession_genrated = no_of_survey_imperession_genrated}&{live_survey = live_survey}&{paused_survey = paused_survey}`}
                            </Typography>
                        </Box>

                    </Stack>
                </form>
            </BodyWaperUI >

            <SnackUI state={snack} setState={setSnack} status={status} message={message} />


            {/* <Hash state={hashModal} setState={sethashModal} /> */}
        </>
    )
}
