import { yupResolver } from '@hookform/resolvers/yup'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Box, Breadcrumbs, Button, Card, FormControl, FormControlLabel, FormHelperText, FormLabel, Link, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link as LinkRRD, useNavigate } from 'react-router-dom'
import * as yup from "yup"
import { addBankDetailsAction } from '../../redux/payment/addBankDetails'
import BodyWaperUI from '../../ui/BodyWaperUI'
import NavbarUI from '../../ui/NavbarUI'
import SidebarUI from '../../ui/SidebarUI'
import SnackUI from '../../common/SnackUI'

const breadcrumb = (
    <Stack spacing={2}>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#fff' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: '#fff' }} color='inherit' href='/'>Home</Link>
            <Link underline="hover" sx={{ color: '#fff' }} color='inherit' href='/payments'>Payments</Link>
            <Link underline="hover" sx={{ color: '#fff' }} color='inherit' href='/payment/add-details'>Bank details</Link>
        </Breadcrumbs>
    </Stack>
)

const paymentDetailsSchema = yup.object({
    holder_name: yup.string().required('Account name is required.'),
    account_no: yup.number().positive().typeError("Please enter a valid account number").required(),
    ifcs_code: yup.string().required('IFSC code is required.'),
    branch: yup.string().required('Branch is required.'),

    account_type: yup.string().required('Account type is required.').typeError("Account type is Required"),
    bussiness_name: yup.string().required('Bussiness name is required.'),
    country: yup.string().required('Country is required.'),
    address: yup.string().required('Address is required.'),
    address2: yup.string().required('Address2 is required.'),
    city: yup.string().required('City is required.'),
    region: yup.string().required('Region is required.'),
    postal_code: yup.number().positive().typeError("Please enter a valid postal code").required(),


}).required();



export default function AddPaymentDetails() {
    // state
    const navigate = useNavigate()
    const dispatch = useDispatch()
const[snake,setSnake] = useState(false)
    const { register, handleSubmit, setError, formState: { errors } } = useForm({ resolver: yupResolver(paymentDetailsSchema), defaultValues: {} });

const {loading,message,status} = useSelector(state=>state.addBankDetails)
    // fn
    const handleBankDetails = async (data) => {


        if (data?.postal_code?.toString()?.length != 6) {
            return setError('postal_code', { type: 'custom', message: 'Please enter a valid postal code' });
        }

        let bankDetails = await dispatch(addBankDetailsAction({
            account_no: Number(data?.account_no),
            postal_code: data?.postal_code,
            ...data
        }))
        if (bankDetails?.payload?.status == 200) {
            setTimeout(()=>{navigate("/payment")},700)
        }
        setSnake(true)

    }


    return (
        <>
            <Helmet>
                <title>PSC | Add Payments details</title>
            </Helmet>

            <NavbarUI breadcrumb={breadcrumb} />
            <SidebarUI />
            <BodyWaperUI>
                <form onSubmit={handleSubmit(handleBankDetails)}>
                    <Card sx={{ p: 2 }}>
                        <Typography variant='h6' sx={{ my: 2 }}>Add Bank Account Details</Typography>

                        <Stack spacing={2}>
                            <TextField {...register('account_no')} helperText={errors?.account_no?.message} error={errors?.account_no?.message}  type='number' label='Back Account No.' placeholder='Enter Bank Account' />
                            <TextField {...register('holder_name')} helperText={errors?.holder_name?.message} error={errors?.holder_name?.message} label={`Account Holder's Name`} placeholder='Enter Account Holder Name' />
                            <TextField {...register('ifcs_code')} helperText={errors?.ifcs_code?.message} error={errors?.ifcs_code?.message} label='IFSC Code' placeholder='Enter IFSC Code' />
                            <TextField {...register('branch')} helperText={errors?.branch?.message} error={errors?.branch?.message} label='Branch' placeholder='Enter Branch' />


                            <FormControl >
                                <FormLabel defaultValue="Individual">Account Type</FormLabel>
                                <RadioGroup>
                                    <FormControlLabel {...register('account_type')}  value="Individual" control={<Radio/>} label="Individual"  />
                                    <FormControlLabel {...register('account_type')} value="Business" control={<Radio />} label="Business" />
                                    <FormHelperText error={errors?. account_type?.message}>{errors?.account_type?.message}</FormHelperText>
                                </RadioGroup>
                                
                            </FormControl>

                            <TextField {...register('bussiness_name')} helperText={errors?.bussiness_name?.message} error={errors?.bussiness_name?.message} label='Bussiness Name' placeholder='Bussiness Name' />
                            <TextField {...register('country')} helperText={errors?.country?.message} error={errors?.country?.message} label='Country' placeholder='Country' />
                            <TextField {...register('address')} helperText={errors?.address?.message} error={errors?.address?.message} label='Address Line' placeholder='Address Line' />
                            <TextField {...register('address2')} helperText={errors?.address2?.message} error={errors?.address2?.message} label='Address Line 2' placeholder='Address Line 2' />
                            <TextField {...register('city')} helperText={errors?.city?.message} error={errors?.city?.message} label='City' placeholder='City' />
                            <TextField {...register('region')} helperText={errors?.region?.message} error={errors?.region?.message} label='Region' placeholder='Region' />
                            <TextField {...register('postal_code')}  helperText={errors?.postal_code?.message} error={errors?.postal_code?.message} label='Postal Code' placeholder='Postal Code' />


                            <Box>
                                <Button type='submit' variant='contained' disabled = {loading}>{loading ? "Submitting..": "Add Bank Details"}</Button>
                                <LinkRRD style={{ textDecoration: 'none' }} to='/payment'><Button sx={{ ml: 2 }} variant='contained' color='error'>Back</Button></LinkRRD>
                            </Box>
                        </Stack>
                    </Card>
                </form>

            </BodyWaperUI>
            <SnackUI status={status} message={message} state={snake} setState={setSnake}/>
        </>
    )
}
