import { Box, Card, CircularProgress, Grid, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { totalRevenueAction } from '../../redux/home/totalRevenue';
import { totalRevenuMonthWiseAction } from '../../redux/home/totalRevenuMonthWise';
import { GraphDashbaord } from './GraphDashbaord';
import CachedIcon from '@mui/icons-material/Cached';
import { getProfileAction } from '../../redux/profile/getPublisherProfile';

export default function Revenue() {
    // state

    const dispatch = useDispatch()
    // state
    const getProfileSelector = useSelector(state => state.getProfile)
    const { data, loading } = getProfileSelector




    // fn
    const handleRefresh = () => {
        dispatch(getProfileAction())
        dispatch(totalRevenuMonthWiseAction())
    }


    // use effect
    useEffect(() => {
        dispatch(totalRevenueAction())
        dispatch(totalRevenuMonthWiseAction())
        dispatch(getProfileAction())
    }, [dispatch])

    return (

        <Card sx={{ p: 2, height: 'auto' }}>
            <Stack direction='row' justifyContent='space-between' >
                <Typography variant='h5'>Revenue</Typography>
                <IconButton onClick={handleRefresh}><CachedIcon /></IconButton>
            </Stack>
            <Grid container spacing={2}>
                <Grid xs={4} item>
                    <Box sx={{ my: 2 }}>
                        <Typography variant='h6'>Total Earnings</Typography>
                        {loading ? <CircularProgress />
                            : <Stack sx={{ mt: 1 }}>
                                <Typography variant='h3' sx={{ fontWeight: 600 }} color='primary'>{Number(data?.total_earned_amount).toFixed(2) || "--"}</Typography>
                            </Stack>
                        }

                    </Box>
                    <Box sx={{ my: 2 }}>
                        <Typography variant='h6'>Total Withdrawable Amount </Typography>
                        {loading
                            ? <CircularProgress />
                            : <Stack sx={{ mt: 1 }}>
                                <Typography variant='h3' sx={{ fontWeight: 600 }} color='primary'>{Number(data?.available_amount).toFixed(2) || "--"}</Typography>
                            </Stack>
                        }

                    </Box>
                </Grid>
                <Grid xs={8} item>
                    <GraphDashbaord />
                </Grid>
            </Grid>
        </Card >
    )
}

