import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SnackUI from '../../common/SnackUI'
import { getBankDetailsAction } from '../../redux/payment/getBankDetails'
import { removeRestoreBankDetailsAction } from '../../redux/payment/removeRestoreBankDetails'

export default function RemoveRestorebankDetails({ bank_id, is_deleted, state, setState }) {
    // state
    const dispatch = useDispatch()
    const removeRestoreBankDetails = useSelector(state => state.removeRestoreBankDetails)
    const { status, message } = removeRestoreBankDetails

    const [snack, setSnack] = useState(false)

    // fn
    const handleClose = () => {
        setState(false)

    }

    const delCatHandle = async () => {
        let rmRsData = await dispatch(removeRestoreBankDetailsAction({ bank_id: bank_id, is_deleted: is_deleted }))
        if (rmRsData?.payload?.status == 200) {
            dispatch(getBankDetailsAction())
        }
        setSnack(true)
        setState(false)
    }




    return (
        <>
            <Dialog open={state} onClose={handleClose}>
                <DialogTitle>Restore / revert bank details!</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to do this action ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={delCatHandle} autoFocus> Agree</Button>
                    <Button onClick={handleClose}>Disagree</Button>
                </DialogActions>
            </Dialog>


            <SnackUI state={snack} setState={setSnack} status={status} message={message} />
        </>

    )
}


RemoveRestorebankDetails.propTypes = {
    bank_id: PropTypes.string,
    state: PropTypes.any,
    setState: PropTypes.any,
    is_deleted: PropTypes.any,
};