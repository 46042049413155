import { yupResolver } from "@hookform/resolvers/yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Button,
  Card,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import SnackUI from "../../common/SnackUI";
import { loginAction } from "../../redux/auth/login";
import { getProfileAction } from "../../redux/profile/getPublisherProfile";

// login schema
const loginSchema = yup
  .object({
    publisher_email: yup.string().required("Email is required."),
    password: yup.string().required("Password is required."),
  })
  .required();

export default function Login() {
  // state
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(loginSchema) });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const login = useSelector((state) => state.login);
  const { status, message, loading } = login;

  const [snack, setSnack] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [autoBlockMsg, setAutoBlockMsg] = useState(false);

  // fn
  const loginHandle = async (data) => {
    setAutoBlockMsg(false)
    localStorage.removeItem('block')
    const loginDispatch = await dispatch(loginAction(data));

    if (loginDispatch?.payload?.status == 200) {
    
      dispatch(getProfileAction());
      setSnack(true);
      localStorage.setItem(
        "ps_token",
        JSON.stringify(loginDispatch?.payload?.token)
      );
      localStorage.setItem(
        "ps_active_usr",
        JSON.stringify(loginDispatch?.payload?.data)
      );
      localStorage.setItem(
        "ps_reg_verify",
        JSON.stringify(loginDispatch?.payload?.data?.varified?.registeration)
      );
      navigate("/");
      window.location.reload();
    }

    setSnack(true);
  };

  useEffect(() => {
    if (localStorage.getItem("block")) {
      setAutoBlockMsg(true);
      setSnack(true)
      
    }
  }, []);
console.log(autoBlockMsg)
  return (
    <>
      <Helmet>
        <title>PSC | login</title>
      </Helmet>

      <Box>
        <Grid container>
          <Grid item xs={12}>
            <Box
              sx={{
                p: 4,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <form onSubmit={handleSubmit(loginHandle)}>
                <Card sx={{ p: 3, width: 400 }}>
                  <Stack alignItems="center" spacing={1} sx={{ my: 2 }}>
                    <Typography variant="h5" color="primary" sx={{ my: 2 }}>
                      Quick Survey Dashboard
                    </Typography>
                  </Stack>
                  <Stack spacing={2} alignItems="center">
                    <TextField
                      fullWidth
                      label="Publisher Email"
                      variant="outlined"
                      {...register("publisher_email")}
                      helperText={errors?.publisher_email?.message}
                      error={errors?.publisher_email?.message}
                    />
                    <FormControl variant="outlined" sx={{ width: "100%" }}>
                      <InputLabel>Password</InputLabel>
                      <OutlinedInput
                        label="Password"
                        variant="outlined"
                        type={passwordVisible ? "text" : "password"}
                        {...register("password")}
                        error={errors?.password?.message}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setPasswordVisible(!passwordVisible)
                              }
                            >
                              {passwordVisible ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <FormHelperText error>
                        {errors?.password?.message}
                      </FormHelperText>
                    </FormControl>

                    <Stack
                      spacing={2}
                      alignItems="center"
                      sx={{ my: 2, width: "100%" }}
                    >
                      <Button
                        fullWidth
                        disabled={loading}
                        variant="contained"
                        type="submit"
                      >
                        {loading ? (
                          <CircularProgress sx={{ color: "#fff" }} />
                        ) : (
                          "Login"
                        )}
                      </Button>
                      <Link
                        to="/register"
                        style={{ textDecoration: "none", color: "#1F90E3" }}
                      >
                        Don&apos;t have an account
                      </Link>
                    </Stack>
                  </Stack>
                </Card>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <SnackUI
        state={snack}
        setState={setSnack}
        status={status}
        message={autoBlockMsg ? localStorage.getItem("block") : message}
      />
    </>
  );
}
