import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Box } from '@mui/system';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Style from "../../css/screen/doc/SideMenu.module.scss";



const sidemenuList = [
    { title: 'Getting Started', url: "?getting-started" },
    { title: 'Profile', url: "?profile" },
    { title: 'Postback', url: "?postback" },
    { title: 'Settings', url: "?settings" },
]


export default function SideMenu() {


    // state--->
    const location = useLocation()?.search

    return (

        <Box className={Style.main}>
            <List>
                {sidemenuList?.map((item, index) => {
                    return (
                        <Link key={index} to={item?.url} style={{ textDecoration: 'none', color: '#000' }}>
                            <ListItem disablePadding selected={location === item?.url}>
                                <ListItemButton>
                                    <ListItemText primary={item?.title} sx={{ color: location === item?.url && '#fff' }} />
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    )
                })}
            </List>
        </Box >
    )
}
