
import { Stack } from '@mui/material'
import React from 'react'
import GettingStarted from './GettingStarted'
import Profile from './Profile'
import Settings from './Settings'


export default function Index() {
    return (
        <Stack spacing={5}>
            <GettingStarted />
            <Profile />
            <Settings />
        </Stack>
    )
}
