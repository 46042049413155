import { combineReducers, configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import { currancyExchangeReducer } from './analytics/currancyExchange'
import { getAnalyticsSurveyDataReducer } from './analytics/getAnalyticsSurveyData'
import { noOfDropedSurveyReducer } from './analytics/noOfDropedSurvey'
import { noOfParticipentReducer } from './analytics/noOfParticipent'
import { noOfQuailifiedSurveyReducer } from './analytics/noOfQualifiedSurvey'
import { noOfSurveyAttemptsReducer } from './analytics/noOfSurveyAttempts'
import { noOfSurveyImpressionGenratedReducer } from './analytics/noOfSurveyImpressionGenrated'
import { analyticsUserDetailsReducer } from './analytics/user/analyticsUserDetails'
import { loginReducer } from './auth/login'
import { registerReducer } from './auth/register'
import { noOfpublisherReducer } from './home/numberOfPublisher'
import { overallConversionRateReducer } from './home/overallConversionRate'
import { perDayUserConversionRasioReducer } from './home/perDayUserConversionRasio'
import { platformSaleReducer } from './home/platformSale'
import { publisherActivityReducer } from './home/publisherActivity'
import { surveyCompleteReducer } from './home/surveyComplete'
import { totalRevenueReducer } from './home/totalRevenue'
import { totalRevenuMonthWiseReducer } from './home/totalRevenuMonthWise'
import { totalUsersReducer } from './home/totalUsers'
import { liveSurveyReducer } from './menu/liveSurvey'
import { pausedSurveyReducer } from './menu/pausedSurvey'
import { addBankDetailsReducer } from './payment/addBankDetails'
import { editBankDetailsReducer } from './payment/editBankDetails'
import { getBankDetailsReducer } from './payment/getBankDetails'
import { getSingleBankDetailsReducer } from './payment/getSingleBankDetails'
import { removeRestoreBankDetailsReducer } from './payment/removeRestoreBankDetails'
import { resentPaymentReducer } from './payment/resentPayment'
import { withdrawRequestReducer } from './payment/withdrawRequest'
import { addpostbackUrlReducer } from './postback/addpostbackUrl'
import { getpublisherhashReducer } from './postback/getHash'
import { addProfileDetailsReducer } from './profile/addProfile'
import { addSocialMediaReducer } from './profile/addSocialMedia'
import { editProfileDetailsReducer } from './profile/editProfile'
import { getProfileReducer } from './profile/getPublisherProfile'
import { getSocialMediaReducer } from './profile/getSocialMediadetails'
import { uplodImageReducer } from './profile/uplodImage'
import { getFAQReducer } from './support/getFAQ'
import { postbackLogsReducer } from './postback/postback_logs'



// combine reducer------>
const rootReducer = combineReducers({
    login: loginReducer,
    register: registerReducer,
    logout: loginReducer,
    // postback url
    addpostbackUrl: addpostbackUrlReducer,
    PostbackLogs:postbackLogsReducer,
    // home
    totalRevenue: totalRevenueReducer,
    getpublisherhash: getpublisherhashReducer,
    surveyComplete: surveyCompleteReducer,
    noOfpublisher: noOfpublisherReducer,
    totalUsers: totalUsersReducer,
    totalRevenuMonthWise: totalRevenuMonthWiseReducer,
    platformSale: platformSaleReducer,
    publisherActivity: publisherActivityReducer,
    perDayUserConversionRasio: perDayUserConversionRasioReducer,
    overAllConvresionRate: overallConversionRateReducer,
    // profile
    addProfileDetails: addProfileDetailsReducer,
    editProfileDetails: editProfileDetailsReducer,
    addSocialMedia: addSocialMediaReducer,
    getProfile: getProfileReducer,
    getSocialMedia: getSocialMediaReducer,
    uplodImage: uplodImageReducer,
    getFAQ: getFAQReducer,

    // analytics data
    currancyExchange: currancyExchangeReducer,
    noOfDropedSurvey: noOfDropedSurveyReducer,
    noOfParticipent: noOfParticipentReducer,
    noOfQualifiedSurvey: noOfQuailifiedSurveyReducer,
    noOfSurveyAttempts: noOfSurveyAttemptsReducer,
    noOfSurveyImpressionGenrated: noOfSurveyImpressionGenratedReducer,
    analyticsUserDetails: analyticsUserDetailsReducer,
    getAnalyticsSurveyData: getAnalyticsSurveyDataReducer,
    // menu
    liveSurvey: liveSurveyReducer,
    pausedSurvey: pausedSurveyReducer,
    // payments
    withdrawRequest: withdrawRequestReducer,
    addBankDetails: addBankDetailsReducer,
    getBankDetails: getBankDetailsReducer,
    removeRestoreBankDetails: removeRestoreBankDetailsReducer,
    getSingleBankDetails: getSingleBankDetailsReducer,
    editBankDetails: editBankDetailsReducer,
    resentPayment: resentPaymentReducer

})


// store managment----->
export const store = configureStore({
    reducer: rootReducer,
    middleware: [thunk, logger],
})