import HomeIcon from "@mui/icons-material/Home";
import PaymentIcon from "@mui/icons-material/Payment";
import Person2Icon from "@mui/icons-material/Person2";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ReplyIcon from "@mui/icons-material/Reply";
import SettingsIcon from "@mui/icons-material/Settings";
import WidgetsIcon from "@mui/icons-material/Widgets";
import { Divider } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Box } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Style from "../css/ui/Sidebar.module.scss";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

export default function SidebarUI() {
  // state--->
  const location = useLocation()?.pathname;

  const getProfileSelector = useSelector((state) => state.getProfile);
  const { data: profileData } = getProfileSelector;

  let menuListPS = [
    {
      title: "Home",
      icon: <HomeIcon sx={{ color: location === "/" && "#fff" }} />,
      path: "/",
    },
    {
      title: "Analytics",
      icon: (
        <QueryStatsIcon sx={{ color: location === "/analytics" && "#fff" }} />
      ),
      path: "/analytics",
    },
    {
      title: "Menu",
      icon: <WidgetsIcon sx={{ color: location === "/menu" && "#fff" }} />,
      path: "/menu",
    },
    {
      title: "Profile",
      icon: <Person2Icon sx={{ color: location === "/profile" && "#fff" }} />,
      path: "/profile",
    },
    {
      title: "Postback",
      icon: <ReplyIcon sx={{ color: location === "/postback" && "#fff" }} />,
      path: "/postback",
    },
    {
      title: "Postback Logs",
      icon: (
        <ManageAccountsIcon
          sx={{ color: location === "/postback-logs" && "#fff" }}
        />
      ),
      path: "/postback-logs",
    },
    {
      title: "Integration",
      icon: (
        <SettingsIcon sx={{ color: location === "/integration" && "#fff" }} />
      ),
      path: "/integration",
    },
    {
      title: "Payments",
      icon: <PaymentIcon sx={{ color: location === "/payment" && "#fff" }} />,
      path: "/payment",
    },
    {
      title: "Support and Knowledge",
      icon: (
        <SupportAgentIcon
          sx={{ color: location === "/support-knowledge" && "#fff" }}
        />
      ),
      path: "/support-knowledge",
    },
    {
      title: "Account manager's Details",
      icon: (
        <ManageAccountsIcon
          sx={{ color: location === "/account-manager-details" && "#fff" }}
        />
      ),
      path: "/account-manager-details",
    },
  ];

  return (
    <Box className={Style.main}>
      <Box className={Style.logo}>
        <img
          src={profileData?.profile_img}
          style={{ width: "80px", height: "80px", fontSize: 14 }}
          id="user_menu"
          alt={profileData?.publisher_name?.charAt(0)}
        />
      </Box>
      <Divider></Divider>
      <List>
        {menuListPS?.map((item, index) => {
          return (
            <Link
              key={index}
              to={item?.path}
              style={{ textDecoration: "none", color: "#000" }}
            >
              <ListItem
                disablePadding
                selected={location === item?.path}
                className={Style.menu}
              >
                <ListItemButton>
                  <ListItemIcon>{item?.icon}</ListItemIcon>
                  <ListItemText
                    primary={item?.title}
                    sx={{ color: location === item?.path && "#fff" }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
          );
        })}
      </List>
    </Box>
  );
}
