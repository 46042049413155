import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "../../config/authAxios"



const initialState = { status: null, loading: false, data: [], error: "", message: "" }


export const resentPaymentAction = createAsyncThunk(
    'payment-requests',
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.get(`/publisher/api/payment-requests/${data?.page}/${data?.limit}`)
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const resentPaymentSlice = createSlice({
    name: 'payment-requests',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(resentPaymentAction.pending, (state) => {
                state.loading = true
            })
            builder.addCase(resentPaymentAction.fulfilled, (state, action) => {
                state.loading = false
                state.data = action?.payload?.data
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
            builder.addCase(resentPaymentAction.rejected, (state, action) => {
                state.loading = false
                state.error = action?.payload?.error
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
        },

})



export const resentPaymentReducer = resentPaymentSlice.reducer