import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import MainRoute from "./routes/MainRoute";
import { themeDesign } from "./theme/Theme";

export default function App() {
  return (
    <ThemeProvider theme={themeDesign}>
      <Provider store={store}>
        <MainRoute />
      </Provider>
    </ThemeProvider>
  );
}
