import CachedIcon from '@mui/icons-material/Cached'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Breadcrumbs, Card, IconButton, Link, Paper, Stack, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import LoadingUI from '../../../common/LoadingUI'
import NoDataFound from '../../../common/NoDataFound'
import { dateConvert } from '../../../helper/dateConverter'
import { analyticsUserDetailsAction } from '../../../redux/analytics/user/analyticsUserDetails'
import BodyWaperUI from '../../../ui/BodyWaperUI'
import NavbarUI from '../../../ui/NavbarUI'
import SidebarUI from '../../../ui/SidebarUI'

const breadcrumb = (
    <Stack spacing={2}>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#fff' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: '#fff' }} color='inherit' href='/'>Home</Link>
            <Link underline="hover" sx={{ color: '#fff' }} color='inherit' href='/analytics'>Analytics</Link>
            <Link underline="hover" sx={{ color: '#fff' }} color='inherit' href='/analytics/user-details'>User details</Link>
        </Breadcrumbs>
    </Stack>
)

export default function UserDetails() {

    // state
    const dispatch = useDispatch()

    const analyticsUserDetailsSelector = useSelector(state => state.analyticsUserDetails)
    const { data, loading } = analyticsUserDetailsSelector

    const [pageSize, setPageSize] = useState(10);
    const [date, setDate] = useState({
        start: null,
        end: null,
    })


    const userDetailsColumns = [
        {
            field: 'S. No.',
            headerName: 'S. No.',
            headerClassName: 'super-app-theme--header',
            renderCell: (index) => index?.api?.getRowIndex(index?.row?._id) + 1
        },
        {
            field: 'publisher_name',
            headerName: 'Publisher',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <Typography>{params?.row?.publisher_name}</Typography>
                )
            },
        },
        {
            field: 'name',
            headerName: 'Name',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <Typography>{params?.row?.name}</Typography>
                )
            },
        },
        {
            field: 'email',
            headerName: 'Email',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            minWidth: 300,
            renderCell: (params) => {
                return (
                    <Typography>{params?.row?.email}</Typography>
                )
            },
        },
        {
            field: 'reward',
            headerName: 'Reward',
            flex: 1,
            minWidth: 200,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Typography>{params?.row?.reward}</Typography>
                )
            },
        },
        {
            field: 'attempts',
            headerName: 'Survey Attempts',
            flex: 1,
            minWidth: 200,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Typography>{params?.row?.attempts}</Typography>
                )
            },
        },
        {
            field: 'survey_complete',
            headerName: 'Survey Completed',
            flex: 1,
            minWidth: 200,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Typography>{params?.row?.survey_complete}</Typography>
                )
            },
        },

    ]

    // fn
    const handleFilterData = () => {
        dispatch(analyticsUserDetailsAction({ s_date: dateConvert(date?.start), e_date: dateConvert(date?.end) }))
    }

    const handleRefresh = () => {
        dispatch(analyticsUserDetailsAction())
    }


    // use effect
    useEffect(() => {
        dispatch(analyticsUserDetailsAction())
    }, [dispatch])



    return (
        <>
            <Helmet>
                <title>PSC | analytics user details</title>
            </Helmet>

            <NavbarUI breadcrumb={breadcrumb} />
            <SidebarUI />
            <BodyWaperUI>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Card sx={{ p: 2 }}>
                        <Typography color='primary'>Filter by Date</Typography>
                        <Stack direction={'row'} spacing={2} sx={{ mt: 1 }} alignItems='center'>
                            <DatePicker
                                label="Start Date"
                                value={date?.start}
                                onChange={(value) => setDate({ ...date, start: value })}
                                renderInput={(params) => <TextField {...params} />}
                                maxDate={date?.end}

                            />
                            <DatePicker
                                label="End Date"
                                value={date?.end}
                                onChange={(value) => setDate({ ...date, end: value })}
                                renderInput={(params) => <TextField {...params} />}
                                minDate={date?.start}
                            />
                            <Box>
                                <IconButton disabled={!date?.start || !date?.end} onClick={handleFilterData} color='primary'><FilterAltIcon /></IconButton>
                            </Box>
                        </Stack>
                    </Card>
                </LocalizationProvider>


                <Card sx={{ my: 2 }}>
                    <Stack direction='row' justifyContent='space-between' alignItems={'center'} sx={{ p: 2 }} >
                        <Typography variant='h6' color='primary'>{`Participant's List`}</Typography>
                        <IconButton onClick={handleRefresh}><CachedIcon /></IconButton>
                    </Stack>
                    <Paper sx={{
                        bgcolor: '#fff',
                        '& .super-app-theme--header': {
                            bgcolor: 'primary.main',
                            color: '#fff',
                        },
                    }} elevation={0}>
                        <DataGrid
                            autoHeight
                            sx={{ border: 0 }}
                            rows={data}
                            columns={userDetailsColumns}
                            getRowId={(row) => row._id}
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                            pagination
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            disableColumnSelector
                            loading={loading ? <LoadingUI /> : false}
                            components={{
                                NoRowsOverlay: () => <NoDataFound />,
                                Toolbar: GridToolbar,
                            }}

                        />
                    </Paper>
                </Card>


            </BodyWaperUI>
        </>
    )
}
