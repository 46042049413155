import { Box, Fab, IconButton } from '@mui/material'
import React from 'react'
import DocBody from './DocBody'
import Index from './pages/Index'
import SideMenu from './SideMenu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export default function Documentation() {
    // state

    const activeUserName = JSON.parse(localStorage.getItem("ps_active_usr"))?.publisher_name

    const navigate = useNavigate()
    return (
        <>
            <Helmet>
                <title>PSC | ducumentation</title>
            </Helmet>

            <Box>
                <SideMenu />
                <DocBody>
                    <Index />
                    <Box sx={{ position: 'fixed', bottom: 16, right: 16 }}>
                        <Fab color="primary" >
                            <IconButton onClick={() => navigate(activeUserName == 1 ? "/" : "/profile")}><ChevronLeftIcon fontSize='large' sx={{ color: "#fff" }} /></IconButton>
                        </Fab>
                    </Box>
                </DocBody>
            </Box >
        </>
    )

}
