import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { Box, Card, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
// import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

export default function NoOfSurveyAttempts({ data, loading }) {


    // state
    // const noOfSurveyAttempts = useSelector(state => state.noOfSurveyAttempts)
    // const { data, loading } = noOfSurveyAttempts

    return (
        <Grid item xs={4}>
            <Card sx={{ p: 2, backgroundImage: 'linear-gradient(to bottom, #6dd5ed,  #2092E3)' }} >
                <Stack spacing={2} alignItems={'center'}>
                    <Box sx={{ width: 70, height: 70, borderRadius: 1, background: "#2092E3", color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <PlaylistAddCheckIcon sx={{ fontSize: 50 }} />
                    </Box>
                    <Typography sx={{ color: '#fff' }} variant='subtitle2'>Number of Survey Attempts</Typography>
                    {loading
                        ? <CircularProgress />
                        : <Typography variant='h3' sx={{ fontWeight: 600, color: '#fff' }}>{data || "--"}</Typography>}
                </Stack>
            </Card>
        </Grid>
    )
}

NoOfSurveyAttempts.propTypes = {
    data: PropTypes.any,
    loading: PropTypes.any,
};
