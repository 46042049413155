import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from '.././screens/home/Home';
import { getProfileAction } from '../redux/profile/getPublisherProfile';
import AccountManegerDetails from '../screens/account_manager/AccountManegerDetails';
import Analytics from '../screens/analytics/Analytics';
import UserDetails from '../screens/analytics/user/UserDetails';
import ForgetPassword from '../screens/auth/ForgetPassword';
import Login from '../screens/auth/Login';
import NewPassword from '../screens/auth/NewPassword';
import Register from '../screens/auth/Register';
import Documentation from '../screens/doc/Documentation';
import Integration from '../screens/integration/Integration';
import Menu from '../screens/menu/Menu';
import NotFoundPage from '../screens/NotFoundPage';
import AddPaymentDetails from '../screens/payment/AddPaymentDetails';
import EditPaymentDetails from '../screens/payment/EditPaymentDetails';
import Payment from '../screens/payment/Payment';
import WithDrawRequest from '../screens/payment/WithDrawRequest';
import PostBack from '../screens/postback/PostBack';
import Profile from '../screens/profile/Profile';
import AddPublisher from '../screens/publisher/AddPublisher';
import SupportKnowledge from '../screens/support_knowledge/SupportKnowledge';
import NewRegistrationVerification from '../screens/varification/NewRegistrationVerification';
import ProtectedRoute from './ProtectedRoute';
import PostbackLogs from '../screens/postback/PostbackLogs';



export default function MainRoute() {
    // state
    const dispatch = useDispatch()



    // use effect
    useEffect(() => {
        dispatch(getProfileAction())
    }, [dispatch])


    return (
        <BrowserRouter>
            <Routes>

                <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
                <Route path="/analytics" element={<ProtectedRoute><Analytics /></ProtectedRoute>} />
                <Route path="/analytics/user-details" element={<ProtectedRoute><UserDetails /></ProtectedRoute>} />
                <Route path="/menu" element={<ProtectedRoute><Menu /></ProtectedRoute>} />
                <Route path="/integration" element={<ProtectedRoute><Integration /></ProtectedRoute>} />
                <Route path="/payment" element={<ProtectedRoute><Payment /></ProtectedRoute>} />
                <Route path="/payment/add-details" element={<ProtectedRoute><AddPaymentDetails /></ProtectedRoute>} />
                <Route path="/payment/edit-details" element={<ProtectedRoute><EditPaymentDetails /></ProtectedRoute>} />
                <Route path="/payment/request-widthdraw" element={<ProtectedRoute><WithDrawRequest /></ProtectedRoute>} />
                <Route path="/publisher/add-publisher" element={<ProtectedRoute><AddPublisher /></ProtectedRoute>} />
                <Route path="/postback" element={<ProtectedRoute><PostBack /></ProtectedRoute>} />
                <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
                <Route path="/doc" element={<ProtectedRoute><Documentation /></ProtectedRoute>} />
                <Route path="/support-knowledge" element={<ProtectedRoute><SupportKnowledge /></ProtectedRoute>} />
                <Route path="/account-manager-details" element={<ProtectedRoute><AccountManegerDetails /></ProtectedRoute>} />
                <Route path="/postback-logs" element={<ProtectedRoute><PostbackLogs /></ProtectedRoute>} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forget-password" element={<ForgetPassword />} />
                <Route path="/new-password" element={<NewPassword />} />
                <Route path="*" element={<NotFoundPage />} />
                
                <Route path="/new-registration-verify" element={<NewRegistrationVerification />} />

            </Routes>
        </BrowserRouter >
    )
}
