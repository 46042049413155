import { Box } from '@mui/material'
import React from 'react'
import Style from "../css/ui/BodyWapper.module.scss"
import PropTypes from 'prop-types';


export default function BodyWaperUI({ children }) {
    return (
        <Box className={Style.main}>
            <Box sx={{ pt: 15, pl: 2, pr: 2, pb: 5 }}>
                {children}
            </Box>
        </Box>
    )
}


BodyWaperUI.propTypes = {
    children: PropTypes.any
};