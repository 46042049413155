import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "../../config/authAxios"



const initialState = { status: null, loading: false, data: [], error: "", message: "" }


export const totalRevenueAction = createAsyncThunk(
    'total revenue',
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.get(`/publisher/api/toal-revenue`)
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const totalRevenueSlice = createSlice({
    name: 'total revenue',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(totalRevenueAction.pending, (state) => {
                state.loading = true
            })
            builder.addCase(totalRevenueAction.fulfilled, (state, action) => {
                state.loading = false
                state.data = action?.payload?.data
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
            builder.addCase(totalRevenueAction.rejected, (state, action) => {
                state.loading = false
                state.error = action?.payload?.error
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
        },
})


export const totalRevenueReducer = totalRevenueSlice.reducer