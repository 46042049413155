import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, Grid, TextField, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from "react-hook-form";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from "yup";
import SnackUI from '../../common/SnackUI';


// login schema
const loginSchema = yup.object({
    email: yup.string().required(),
}).required();


export default function ForgetPassword() {
    // state
    const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(loginSchema) });

    const navigate = useNavigate()

    const login = useSelector(state => state.login)
    const { status, message } = login

    const [snack, setSnack] = useState(false)
    // fn
    const forgetPasswordHandle = async (data) => {
        return data
    }



    return (
        <>
            <Helmet>
                <title>PSC | forget-password</title>
            </Helmet>

            <Box>
                <Grid container>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(forgetPasswordHandle)}>
                            <Stack spacing={3} sx={{ p: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                                <Card sx={{ p: 3, width: 400 }}>
                                    <Stack alignItems='center' spacing={1} sx={{ my: 2 }}>
                                        <img src={'assets/img/logo.png'} width='10%' />
                                        <Typography variant='h5' color='primary' sx={{ my: 2 }}>SPC Panel</Typography>
                                    </Stack>
                                    <Stack alignItems={'center'} spacing={4}>
                                        <Typography variant='p' sx={{ mt: 2 }}>You will receive an e-mail in maximum 60 seconds</Typography>
                                        <TextField fullWidth label="Email" variant="outlined"  {...register('email')} helperText={errors?.email?.message} error={errors?.email?.message} />
                                        <Button fullWidth variant='contained' onClick={() => navigate('/new-password')}>Send Email</Button>
                                    </Stack>
                                </Card>
                            </Stack>
                        </form>
                    </Grid>
                </Grid>
            </Box >


            <SnackUI state={snack} setState={setSnack} status={status} message={message} />
        </>
    )
}
