import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Breadcrumbs, Grid, Link, Stack } from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet'
import BodyWaperUI from '../../ui/BodyWaperUI'
import NavbarUI from '../../ui/NavbarUI'
import SidebarUI from '../../ui/SidebarUI'
import OverAllConversionRate from './OverAllConversionRate'
import PerDayUserConversionRasio from './PerDayUserConversionRasio'
import Revenue from './Revenue'
import TotalUser from './TotalUser'

const breadcrumb = (
    <Stack spacing={2} >
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" sx={{ color: '#fff' }} />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: '#fff' }} color='inherit' href='/'>Home</Link>
        </Breadcrumbs>
    </Stack>
)

export default function Home() {
    return (
        <>

            <Helmet>
                <title>PSC | home</title>
            </Helmet>


            <NavbarUI breadcrumb={breadcrumb} />
            <SidebarUI />
            <BodyWaperUI>


                {/* dashbaord */}
                <Grid container spacing={2}>
                    <Grid xs={12} item><Revenue /></Grid>
                    {/* <Grid xs={4} item><PlatformRevenue /></Grid> */}
                </Grid>


                {/* user section */}
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid xs={6} item><TotalUser /></Grid>
                    <Grid xs={6} item><OverAllConversionRate /></Grid>
                    {/* <Grid xs={4} item><TotalSurveyComplete /></Grid> */}
                    {/* <Grid xs={4} item><TotalPublisher /></Grid> */}
                </Grid>



                {/* Activity &  other status */}
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    {/* <Grid xs={12} item><RecentActivity /></Grid> */}
                    <Grid xs={12} item><PerDayUserConversionRasio /></Grid>
                    {/* <Grid xs={4} item><ConversationRate /></Grid> */}
                </Grid>

            </BodyWaperUI>
        </>
    )
}
