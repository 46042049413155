import {GrMail} from 'react-icons/gr'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { Accordion, AccordionDetails, AccordionSummary, Breadcrumbs, Button, Link, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import LoadingUI from '../../common/LoadingUI'
import { getFAQAction } from '../../redux/support/getFAQ'
import BodyWaperUI from '../../ui/BodyWaperUI'
import NavbarUI from '../../ui/NavbarUI'
import SidebarUI from '../../ui/SidebarUI'


const breadcrumb = (
    <Stack spacing={2}>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#fff' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: '#fff' }} color='inherit' href='/'>Home</Link>
            <Link underline="hover" sx={{ color: '#fff' }} color='inherit' href='/support-knowledge'>Support & Knowledge</Link>
        </Breadcrumbs>
    </Stack >
)

export default function SupportKnowledge() {

    const FAQ = useSelector(state => state.getFAQ)
    const { data, loading } = FAQ
    const support_knowladges = data?.support_knowladges || []
    const support_email = data?.support_email
    const support_mobile = data?.support_mobile
    const getProfileSelector = useSelector(state => state.getProfile)
    const { data: publisherData } = getProfileSelector

    const dispatch = useDispatch()



    useEffect(() => {
        dispatch(getFAQAction())
    }, [dispatch])



    return (
        <>
            <Helmet>
                <title>PSC | Support & Knowledge</title>
            </Helmet>


            <NavbarUI breadcrumb={breadcrumb} />
            <SidebarUI />
            <BodyWaperUI>
                {loading
                    ? <LoadingUI />
                    : support_knowladges?.map((item, indx) => {
                        return (
                            <>
                                <Accordion key={indx}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography color='primary' >Qns : {item?.title}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {item?.answers?.map((ans, idx) => {
                                            return (
                                                <>
                                                    <Stack direction='row' spacing={1}>
                                                        <Typography variant='subtitle2' key={idx}>Ans</Typography>
                                                        <Typography color='primary' variant='subtitle2' key={idx}>{ans}</Typography>
                                                    </Stack>
                                                </>
                                            )
                                        })}

                                    </AccordionDetails>
                                </Accordion>
                            </>
                        )
                    })}
                <Stack direction='row' spacing={2} sx={{ mt: 1 }}>
                    {support_mobile ? <Button variant='contained' endIcon={<GrMail/>} href={`https://mail.google.com/mail/?view=cm&fs=1&to=${publisherData?.m_email}`} >Connect with Email</Button> : null}
                    {support_email ? <Button variant='contained' color='success' endIcon={<WhatsAppIcon />} href={`https://wa.me/${publisherData?.m_mobile}/?text=urlencodedtext`}>Connect with whatsapp</Button> : null}
                </Stack>
            </BodyWaperUI>
        </>
    )
}
