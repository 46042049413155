import FilterAltIcon from '@mui/icons-material/FilterAlt'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Breadcrumbs, Card, Grid, IconButton, Link, Stack, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { dateConvert } from '../../helper/dateConverter'
import { getAnalyticsSurveyDataAction } from '../../redux/analytics/getAnalyticsSurveyData'
import BodyWaperUI from '../../ui/BodyWaperUI'
import NavbarUI from '../../ui/NavbarUI'
import SidebarUI from '../../ui/SidebarUI'
import NoOfParticipent from './NoOfParticipent'
import NoOfQualifiedSurvey from './NoOfQualifiedSurvey'
import NoOfSurveyAttempts from './NoOfSurveyAttempts'


const breadcrumb = (
    <Stack spacing={2}>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#fff' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: '#fff' }} color='inherit' href='/'>Home</Link>
            <Link underline="hover" sx={{ color: '#fff' }} color='inherit' href='/analytics'>Analytics</Link>
        </Breadcrumbs>
    </Stack>
)

export default function Analytics() {
    // state
    const getAnalyticsSurveyDataSelector = useSelector(state => state.getAnalyticsSurveyData)
    const { data, loading } = getAnalyticsSurveyDataSelector

    const dispatch = useDispatch()

    const [date, setDate] = useState({
        start: null,
        end: null,
    })

    // fn
    const handleFilterData = () => {
        // dispatch(noOfSurveyAttemptsAction({ s_date: dateConvert(date?.start), e_date: dateConvert(date?.end) }))
        // dispatch(noOfParticipentAction({ s_date: dateConvert(date?.start), e_date: dateConvert(date?.end) }))
        // dispatch(noOfQuailifiedSurveyAction({ s_date: dateConvert(date?.start), e_date: dateConvert(date?.end) }))
        // dispatch(noOfDropedSurveyAction({ s_date: dateConvert(date?.start), e_date: dateConvert(date?.end) }))
        // dispatch(noOfSurveyImpressionGenratedAction({ s_date: dateConvert(date?.start), e_date: dateConvert(date?.end) }))
        dispatch(getAnalyticsSurveyDataAction({ s_date: dateConvert(date?.start), e_date: dateConvert(date?.end) }))

    }


    useEffect(() => {
        dispatch(getAnalyticsSurveyDataAction())
        // dispatch(noOfParticipentAction())
        // dispatch(noOfSurveyAttemptsAction())
    }, [dispatch])


    return (
        <>
            <Helmet>
                <title>PSC | analytics</title>
            </Helmet>


            <NavbarUI breadcrumb={breadcrumb} />
            <SidebarUI />
            <BodyWaperUI>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Card sx={{ p: 2 }}>
                        <Typography color='primary'>Filter by Date</Typography>
                        <Stack direction={'row'} spacing={2} sx={{ mt: 1 }} alignItems='center'>
                            <DatePicker
                                label="Start Date"
                                value={date?.start}
                                onChange={(value) => setDate({ ...date, start: value })}
                                renderInput={(params) => <TextField {...params} />}
                                maxDate={date?.end}

                            />
                            <DatePicker
                                label="End Date"
                                value={date?.end}
                                onChange={(value) => setDate({ ...date, end: value })}
                                renderInput={(params) => <TextField {...params} />}
                                minDate={date?.start}
                            />
                            <Box>
                                <IconButton disabled={!date?.start || !date?.end} onClick={handleFilterData} color='primary'><FilterAltIcon /></IconButton>
                            </Box>
                        </Stack>
                    </Card>
                </LocalizationProvider>

                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <NoOfSurveyAttempts loading={loading} data={data?.no_of_survey_atempts} />
                    <NoOfParticipent loading={loading} data={data?.no_of_survey_participent} />
                    <NoOfQualifiedSurvey loading={loading} data={data?.no_of_survey_completed} />


                    {/* <NoOfDropedSurvey /> */}
                    {/* <NoOfSurveyImpressionGenrated /> */}
                </Grid>

            </BodyWaperUI>
        </>
    )
}
