import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Box, Card, CardActionArea, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
// import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function NoOfParticipent({ data, loading }) {

    // state
    // const noOfParticipentSelector = useSelector(state => state.noOfParticipent)
    // const { data, loading } = noOfParticipentSelector

    return (
        <Grid item xs={4}>
            <Card sx={{ p: 2, backgroundImage: 'linear-gradient(to bottom, #6dd5ed,  #2092E3)' }} >
                <Link style={{ textDecoration: 'none' }} to='/analytics/user-details'>
                    <CardActionArea>
                        <Stack spacing={2} alignItems={'center'}>
                            <Box sx={{ width: 70, height: 70, borderRadius: 1, background: "#2092E3", color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <PeopleAltIcon sx={{ fontSize: 50 }} />
                            </Box>
                            <Typography sx={{ color: '#fff' }} variant='subtitle2'>Number of Participants</Typography>
                            {loading
                                ? <CircularProgress />
                                : <Typography variant='h3' sx={{ fontWeight: 600, color: '#fff' }}>{data || "--"}</Typography>
                            }

                        </Stack>
                    </CardActionArea>
                </Link>
            </Card>
        </Grid >
    )
}



NoOfParticipent.propTypes = {
    data: PropTypes.any,
    loading: PropTypes.any,
};