import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "../../config/authAxios"



const initialState = { status: null, loading: false, data: [], error: "", message: "" }


export const removeRestoreBankDetailsAction = createAsyncThunk(
    'remove-restore-bank',
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.put(`/publisher/api/remove-restore-bank`, { ...data })
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const removeRestoreBankDetailsSlice = createSlice({
    name: 'remove-restore-bank',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(removeRestoreBankDetailsAction.pending, (state) => {
                state.loading = true
            })
            builder.addCase(removeRestoreBankDetailsAction.fulfilled, (state, action) => {
                state.loading = false
                state.data = action?.payload?.data
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
            builder.addCase(removeRestoreBankDetailsAction.rejected, (state, action) => {
                state.loading = false
                state.error = action?.payload?.error
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
        },

})



export const removeRestoreBankDetailsReducer = removeRestoreBankDetailsSlice.reducer