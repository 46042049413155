import CachedIcon from '@mui/icons-material/Cached'
import { Card, IconButton, Paper, Stack, Typography } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingUI from '../../common/LoadingUI'
import NoDataFound from '../../common/NoDataFound'
import { perDayUserConversionRasioAction } from '../../redux/home/perDayUserConversionRasio'

export default function PerDayUserConversionRasio() {
    // state
    const dispatch = useDispatch()
    const perDayUserConversionRasioSelector = useSelector(state => state.perDayUserConversionRasio)
    const { data, loading } = perDayUserConversionRasioSelector

    const [pageSize, setPageSize] = useState(10);


    const resentActivityColumns = [
        {
            field: 's_no',
            headerName: 'S. no.',
            filterable: false,
            flex: 1,
            minWidth: 30,
            headerClassName: 'super-app-theme--header',
            renderCell: (index) => index?.api?.getRowIndex(index.row._id) + 1
        },
        {
            field: 'users',
            headerName: 'User count',
            flex: 1,
            minWidth: 200,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'date',
            headerName: 'Date',
            flex: 1,
            minWidth: 200,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <>
                        <Typography>{params?.row?.date?.split("T")[0]}</Typography>
                    </>
                )
            }

        },
        {
            field: 'conversion_rasio',
            headerName: 'Conversion Rate',
            flex: 1,
            minWidth: 200,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <>
                        <Typography>{params?.row?.conversion_rasio}%</Typography>
                    </>
                )
            }
        },

    ]

    // fn
    const handleRefresh = () => {
        dispatch(perDayUserConversionRasioAction())
    }

    // use effect
    useEffect(() => {
        dispatch(perDayUserConversionRasioAction())
    }, [dispatch])

    return (

        <Card>
            <Stack direction='row' justifyContent='space-between' alignItems={'center'} sx={{ p: 2 }} >
                <Typography variant='h5'>Daily Conversion Report</Typography>
                <IconButton onClick={handleRefresh}><CachedIcon /></IconButton>
            </Stack>
            <Paper sx={{
                bgcolor: '#fff',
                '& .super-app-theme--header': {
                    bgcolor: 'primary.main',
                    color: '#fff',
                },
            }} elevation={0}>
                <DataGrid
                    autoHeight={true}
                    sx={{ border: 0 }}
                    rows={data}
                    columns={resentActivityColumns}
                    getRowId={(row) => row.user_id}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                    pagination
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    disableColumnSelector
                    loading={loading ? <LoadingUI /> : false}
                    components={{
                        NoRowsOverlay: () => <NoDataFound />,
                        Toolbar: GridToolbar,
                    }}

                />
            </Paper>
        </Card>
    )
}
