import { yupResolver } from '@hookform/resolvers/yup'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Box, Breadcrumbs, Button, Card, Link, Stack, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link as LinkRRD, useNavigate } from 'react-router-dom'
import * as yup from "yup"
import SnackUI from '../../common/SnackUI'
import { withdrawRequestAction } from '../../redux/payment/withdrawRequest'
import BodyWaperUI from '../../ui/BodyWaperUI'
import NavbarUI from '../../ui/NavbarUI'
import SidebarUI from '../../ui/SidebarUI'

const breadcrumb = (
    <Stack spacing={2}>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#fff' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: '#fff' }} color='inherit' href='/'>Home</Link>
            <Link underline="hover" sx={{ color: '#fff' }} color='inherit' href='/payments'>Payments</Link>
            <Link underline="hover" sx={{ color: '#fff' }} color='inherit' href='/payment/add-details'>Bank details</Link>
        </Breadcrumbs>
    </Stack>
)

const paymentDetailsSchema = yup.object({
    amount: yup.string().required('Amount is required.'),
}).required();



export default function WithDrawRequest() {
    // state

    const withdrawRequest = useSelector(state => state.withdrawRequest)
    const { status, message } = withdrawRequest

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [snack, setSnack] = useState(false)

    const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(paymentDetailsSchema), defaultValues: {} });

    // fn
    const handleBankDetails = async (data) => {
        let bankDetails = await dispatch(withdrawRequestAction({ amount: data?.amount }))
        if (bankDetails?.payload?.status == 200) {
            navigate("/payment")
        }
        setSnack(true)
    }

    return (
        <>
            <Helmet>
                <title>PSC | Add Payments details</title>
            </Helmet>

            <NavbarUI breadcrumb={breadcrumb} />
            <SidebarUI />
            <BodyWaperUI>
                <form onSubmit={handleSubmit(handleBankDetails)}>
                    <Card sx={{ p: 2 }}>
                        <Typography variant='h6' sx={{ my: 2 }}>Withdraw Payment Request</Typography>

                        <Stack spacing={2}>
                            <TextField {...register('amount')} helperText={errors?.amount?.message} error={errors?.amount?.message} label='Amount' placeholder='Amount' />


                            <Box>
                                <Button type='submit' variant='contained'>Request</Button>
                                <LinkRRD style={{ textDecoration: 'none' }} to='/payment'><Button sx={{ ml: 2 }} variant='contained' color='error'>Back</Button></LinkRRD>
                            </Box>
                        </Stack>
                    </Card>
                </form>

            </BodyWaperUI>

            <SnackUI state={snack} setState={setSnack} status={status} message={message} />
        </>
    )
}
