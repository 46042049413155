import { CategoryScale, Chart as ChartJS, Filler, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from 'chart.js';
import moment from 'moment';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import LoadingUI from '../../common/LoadingUI';


const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
        legend: {
            display: false
        }
    },
    scales: {
        x: {
            grid: {
                display: false
            }
        },
        y: {
            grid: {
                display: false
            }
        }
    }
};

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

export function GraphDashbaord() {
    // state
    const totalRevenuMonthWiseSelector = useSelector(state => state.totalRevenuMonthWise)
    const { data, loading } = totalRevenuMonthWiseSelector


    const graphData = {
        labels: data?.map(items => moment(items?.created_at).format("DD/MM/YYYY")),
        datasets: [
            {
                fill: true,
                data: data?.map(items => items?.reward),
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };

    return (
        <>
            {
                loading
                    ? <LoadingUI />
                    : <Line options={options} data={graphData} width='100%' height='35%' />
            }

        </>
    )
}

