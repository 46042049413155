import { yupResolver } from '@hookform/resolvers/yup';
import {
    Box,
    Button,
    Card,
    //  Modal,
    TextField, Typography
} from '@mui/material';
import {
    // Box,
    Stack
} from '@mui/system';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import LoadingUI from '../../common/LoadingUI';
import SnackUI from '../../common/SnackUI';
// import ModalStyle from "../../css/comman/Modal.module.scss";
import { addSocialMediaAction } from '../../redux/profile/addSocialMedia';
import { getSocialMediaAction } from '../../redux/profile/getSocialMediadetails';

// login schema
const socialMediaSchema = yup.object({
    facebook: yup.string(),
    gmail: yup.string(),
    instagram: yup.string(),
    twitter: yup.string(),
}).required();

export default function AddSocialMeida({
    //  state,
    setState }) {
    // State

    // state
    const dispatch = useDispatch()

    const addSocialMediaSelector = useSelector(state => state.addSocialMedia)
    const { status, message } = addSocialMediaSelector

    const getSocialMediaSelector = useSelector(state => state.getSocialMedia)
    const { data, loading } = getSocialMediaSelector

    const { register, reset, handleSubmit } = useForm({ resolver: yupResolver(socialMediaSchema), defaultValues: {} });

    const [snack, setSnack] = useState(false)


    // fn
    const handleAddSocialMedia = async (data) => {
        let socialMedia = await dispatch(addSocialMediaAction(data))
        if (socialMedia?.payload?.status == 200) {
            setSnack(true)
        }
        setState(false)
    }

    const handelGetSocialMedia = async () => {
        const getSocialMediaData = await dispatch(getSocialMediaAction())
        if (getSocialMediaData?.payload?.status == 200) {
            dispatch(getSocialMediaAction())
        }
    }

    // useEffect
    useEffect(() => {
        let defaultValues = {}

        defaultValues.facebook = data?.social_media?.facebook
        defaultValues.gmail = data?.social_media?.gmail
        defaultValues.instagram = data?.social_media?.instagram
        defaultValues.twitter = data?.social_media?.twitter

        reset(defaultValues)

        handelGetSocialMedia()
    }, [dispatch])

    return (
        <>
            {/* <Modal open={state} onClose={() => setState(false)}> */}
            <Card sx={{ p: 3 }}>
                <form onSubmit={handleSubmit(handleAddSocialMedia)}>
                    {/* <Box className={ModalStyle.modal}> */}
                    <Stack sx={{ my: 3 }}>
                        <Typography variant='h5'>Social Media</Typography>
                        <Typography variant='subtitle2'>Add your social media details</Typography>
                    </Stack>
                    {loading
                        ? <LoadingUI />
                        : <>
                            <Stack spacing={2}>
                                <TextField defaultValue={data?.social_media?.facebook} variant='outlined' label='Facebook' placeholder='Add Facebook link' {...register('facebook')} />
                                <TextField defaultValue={data?.social_media?.gmail} variant='outlined' label='Gmail' placeholder='Add Gmail link' {...register('gmail')} />
                                <TextField defaultValue={data?.social_media?.instagram} variant='outlined' label='Instagram' placeholder='Add Instagram link' {...register('instagram')} />
                                <TextField defaultValue={data?.social_media?.twitter} variant='outlined' label='Twitter' placeholder='Add Twitter link' {...register('twitter')} />
                            </Stack>

                        </>}
                    <Box sx={{ my: 2 }}>
                        <Button variant='contained' type='submit'>Add Social Media</Button>
                    </Box>
                    {/* </Box> */}
                </form>
            </Card>
            {/* </Modal> */}

            <SnackUI state={snack} setState={setSnack} status={status} message={message} />
        </>
    )
}



AddSocialMeida.propTypes = {
    state: PropTypes.any,
    setState: PropTypes.any
};
