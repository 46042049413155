import LiveTvIcon from '@mui/icons-material/LiveTv'
import { Box, Card, CircularProgress, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { liveSurveyAction } from '../../redux/menu/liveSurvey'


export default function LiveSurvey() {
    // state
    const dispatch = useDispatch()
    const liveSurveySelector = useSelector(state => state.liveSurvey)
    const { data, loading } = liveSurveySelector


    // useEffect
    useEffect(() => {
        dispatch(liveSurveyAction())
    }, [dispatch])


    return (
        <Grid item xs={4}>
            <Card sx={{ p: 2, backgroundImage: 'linear-gradient(to bottom, #6dd5ed,  #2092E3)' }} >
                <Stack spacing={2} alignItems={'center'}>
                    <Box sx={{ width: 70, height: 70, borderRadius: 1, background: "#2092E3", color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <LiveTvIcon sx={{ fontSize: 50 }} />
                    </Box>
                    <Typography sx={{ color: '#fff' }} variant='subtitle2'>Live Surveys</Typography>
                    {loading
                        ? <CircularProgress />
                        : <Typography variant='h3' sx={{ fontWeight: 600, color: '#fff' }}>{data || "--"}</Typography>
                    }
                </Stack>
            </Card>
        </Grid >
    )
}
