import { Box } from '@mui/material'
import React from 'react'
import Style from "../../css/screen/doc/DocBody.module.scss"
import PropTypes from 'prop-types';

export default function DocBody({ children }) {
    return (
        <Box className={Style.main}>
            <Box sx={{ pt: 10, pl: 2, pr: 2, pb: 5 }}>
                {children}
            </Box>
        </Box>
    )
}

DocBody.propTypes = {
    children: PropTypes.any
};
