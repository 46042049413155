export const dateConvert = (date) => {

    let Year = date?.$y,
        Month = (date?.$M + 1),
        Day = date?.$D,
        Hour = date?.$H,
        Minutes = date?.$m,
        Seconds = date?.$s,
        finalDate = null

    if (Month < 10) Month = `0${Month}`
    if (Day < 10) Day = `0${Day}`
    if (Hour < 10) Hour = `0${Hour}`
    if (Minutes < 10) Minutes = `0${Minutes}`
    if (Seconds < 10) Seconds = `0${Seconds}`

    finalDate = `${Year}-${Month}-${Day}T${Hour}:${Minutes}:${Seconds}.000Z`


    return finalDate

}