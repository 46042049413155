import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import authAxios from "../../config/authAxios"




const initialState = { status: null, loading: false, data: [], error: "", message: "" }


export const getPostbaclLogsAction = createAsyncThunk(
    'postback-logs',
    async (data, { rejectWithValue }) => {
        try {
            const res = await authAxios.get(`/publisher/api/get-postback-logs`,)
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const getPostbaclLogsSlice = createSlice({
    name: 'postback-log-details',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(getPostbaclLogsAction.pending, (state) => {
                state.loading = true
            })
            builder.addCase(getPostbaclLogsAction.fulfilled, (state, action) => {
                state.loading = false
                state.data = action?.payload?.data
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
            builder.addCase(getPostbaclLogsAction.rejected, (state, action) => {
                state.loading = false
                state.error = action?.payload?.error
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
        },

})



export const postbackLogsReducer = getPostbaclLogsSlice.reducer