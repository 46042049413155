import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, Grid, TextField, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from "yup";
import SnackUI from '../../common/SnackUI';
import OtpInput from 'react18-input-otp';
import { Helmet } from 'react-helmet';


// login schema
const loginSchema = yup.object({
    newPassword: yup.string().required(),
    confirmNewPassword: yup.string().required(),
}).required();


export default function NewPassword() {
    // state
    const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(loginSchema) });

    const navigate = useNavigate()

    const login = useSelector(state => state.login)
    const { status, message } = login

    const [snack, setSnack] = useState(false)
    const otpInputs = useState(null)[0]
    // fn
    const newPasswordHandle = async (data) => {
        return data
    }



    return (
        <>
            <Helmet>
                <title>PSC | new-password</title>
            </Helmet>

            <Box>
                <Grid container>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(newPasswordHandle)}>

                            <Stack spacing={3} sx={{ p: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                                <Card sx={{ p: 3, width: 400 }}>
                                    <Stack alignItems='center' spacing={1} sx={{ my: 2 }}>
                                        <img src={'assets/img/logo.png'} width='10%' />
                                        <Typography variant='h5' color='primary' sx={{ my: 2 }}>SPC Panel</Typography>
                                    </Stack>
                                    <Stack alignItems={'center'} spacing={4}>
                                        <Typography variant='p' sx={{ my: 1 }}>You can create new password by verifing OTP , Which has beend sent to you registed emial.</Typography>
                                        <OtpInput value={otpInputs} numInputs={6} separator={<span>&nbsp;&nbsp;&nbsp; </span>} inputStyle={{ width: 40, height: 40, borderRadius: 10, border: "1px solid #000" }} />
                                        <Button disabled={!otpInputs}>Verify OTP</Button>

                                        <TextField fullWidth label="New Password" variant="outlined"  {...register('newPassword')} helperText={errors?.newPassword?.message} error={errors?.newPassword?.message} />
                                        <TextField fullWidth label="Confirm New Password" variant="outlined"  {...register('confirmNewPassword')} helperText={errors?.confirmNewPassword?.message} error={errors?.confirmNewPassword?.message} />
                                        <Button fullWidth variant='contained' onClick={() => navigate('/new-password')}>Change Password</Button>
                                    </Stack>
                                </Card>
                            </Stack>
                        </form>
                    </Grid>
                </Grid>
            </Box >


            <SnackUI state={snack} setState={setSnack} status={status} message={message} />
        </>
    )
}
