import { yupResolver } from '@hookform/resolvers/yup';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Avatar, Badge, Box, Button, Card, CircularProgress, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from "yup";
import SnackUI from '../../common/SnackUI';
import { registerAction } from '../../redux/auth/register';
import { uplodImageAction } from '../../redux/profile/uplodImage';



// login schema
const registerSchema = yup.object({
    publisher_name: yup.string().required(),
    publisher_email: yup.string().required(),
    password: yup.string().required(),
    confirmPassword: yup.string().required(),
}).required();

export default function Register() {
    // state
    const { register, setError, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(registerSchema) });
    // state

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const registerSelector = useSelector(state => state.register)
    const { status, message, loading } = registerSelector


    const [snack, setSnack] = useState(false)
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)
    const [avatar, setAvatar] = useState();


    // fn
    const handleavatar = async (e) => {
        const formData = new FormData();
        formData.set("file", e.target.files[0]);
        let uploadedImg = await dispatch(uplodImageAction(formData))
        setAvatar(uploadedImg?.payload?.data?.url)
    }


    const registerHandle = async (data) => {
        if (!avatar) {
            return setError('avatarError', { type: 'custom', message: 'Logo is required' });
        }
        if (data?.password !== data?.confirmPassword) {
            return setError('confirmPassword', { type: 'custom', message: 'Password and Confrim password should be same.' });
        }
        let registerDispatch = await dispatch(registerAction({ profile_img: avatar, ...data }))
        if (registerDispatch?.payload?.status == 200) {
            localStorage.setItem('ps_token', JSON.stringify(registerDispatch?.payload?.token));
            localStorage.setItem('ps_active_usr', JSON.stringify(registerDispatch?.payload?.data));
            localStorage.setItem('ps_reg_verify', JSON.stringify(registerDispatch?.payload?.data?.varified?.registeration));
            navigate('/new-registration-verify');
            setSnack(true)
            window.location.reload()
        }
        setSnack(true)

    }


    return (
        <>
            <Helmet>
                <title>PSC | register</title>
            </Helmet>

            <Box>
                <Grid container>
                    <Grid item xs={12}>
                        <Box sx={{ p: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                            <form onSubmit={handleSubmit(registerHandle)}>
                                <Card sx={{ p: 3, width: 400 }}>
                                    <Stack alignItems='center' spacing={1} sx={{ my: 2 }}>
                                        {/* <img src={'assets/img/logo.png'} width='10%' /> */}
                                        <Typography variant='h5' color='primary' sx={{ my: 2 }}>Quick Survey Dashboard</Typography>

                                        <input accept="image/*" name="avatar" id="upload-avatar-pic" type="file" hidden onChange={handleavatar} />
                                        <label htmlFor="upload-avatar-pic">

                                            <Badge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} badgeContent={<EditIcon />} >
                                                <Avatar src={avatar} sx={{ width: 60, height: 60 }} />
                                            </Badge>
                                        </label>
                                        {errors?.avatarError?.message && <Typography variant='subtitle1' color='error'>{errors?.avatarError?.message}</Typography>}

                                    </Stack>
                                    <Stack spacing={2}>
                                        <TextField fullWidth {...register('publisher_name')} label="Publisher Name" variant="outlined" helperText={errors?.publisher_name?.message} error={errors?.publisher_name?.message} />
                                        <TextField fullWidth {...register('publisher_email')} label="Publisher Email" variant="outlined" helperText={errors?.publisher_email?.message} error={errors?.publisher_email?.message} />


                                        <FormControl variant="outlined">
                                            <InputLabel>Password</InputLabel>
                                            <OutlinedInput label='Password' variant="outlined" type={passwordVisible ? 'text' : 'password'} {...register('password')} error={errors?.password?.message}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={() => setPasswordVisible(!passwordVisible)}>
                                                            {passwordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                } />
                                            <FormHelperText error>
                                                {errors?.password?.message}
                                            </FormHelperText>
                                        </FormControl>

                                        <FormControl variant="outlined">
                                            <InputLabel>Confirm Password</InputLabel>
                                            <OutlinedInput label='Confrim Password' variant="outlined" type={confirmPasswordVisible ? 'text' : 'password'} {...register('confirmPassword')} error={errors?.confirmPassword?.message}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}>
                                                            {confirmPasswordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                } />
                                            <FormHelperText error>
                                                {errors?.confirmPassword?.message}
                                            </FormHelperText>
                                        </FormControl>



                                        <Stack spacing={2} alignItems='center' sx={{ my: 2 }}>
                                            <Button disabled={loading} fullWidth variant='contained' type='submit'>{loading ? <CircularProgress sx={{ color: '#fff' }} /> : 'Register'}</Button>
                                            <Link to="/login" style={{ textDecoration: 'none', color: '#1F90E3' }} >Alreaady have an account</Link>
                                        </Stack>
                                    </Stack>
                                </Card>
                            </form>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
            <SnackUI state={snack} setState={setSnack} status={status} message={message} />
        </>
    )
}
