import { Logout } from '@mui/icons-material';
import ContactsIcon from '@mui/icons-material/Contacts';
import InfoIcon from '@mui/icons-material/Info';
import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Stack } from '@mui/system';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import SnackUI from '../common/SnackUI';
import Style from "../css/ui/Navbar.module.scss";
import { logoutAction } from '../redux/auth/logout';


export default function NavbarUI({ breadcrumb }) {
    // state
    const navigate = useNavigate()
    const dispatch = useDispatch()


    const logoutSelector = useSelector(state => state.logout)
    const { status, message } = logoutSelector

    const getProfileSelector = useSelector(state => state.getProfile)
    const { data: profileData } = getProfileSelector

    const activeUserName = JSON.parse(localStorage.getItem("ps_active_usr"))?.publisher_name



    const [meunVisible, setMenuVisible] = useState(false)
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [snack, setSnack] = useState(false)

    // fun
    const menuHandle = () => {
        setMenuVisible(false)
    }
    const handleLogout = async () => {
        const logoutDispatch = await dispatch(logoutAction())
        if (logoutDispatch?.payload?.status == 200) {
            localStorage.removeItem("ps_active_usr")
            localStorage.removeItem("ps_token")
            localStorage.removeItem("ps_reg_verify")
            navigate('/login')
            setSnack(true)
        }
    }




    return (

        <>
            {/* survey publisher client--SPC */}
            <Box>
                <Box elevation={0} className={Style.main}>
                    <Box sx={{ mx: 2, display: 'flex', alignItems: 'center', width: '100%' }} >
                        <Box sx={{ flexGrow: 1 }}>
                            <Typography sx={{ color: "#fff" }} variant="h6" component="div">Quick Survey Dashboard</Typography>
                            {breadcrumb}
                        </Box>

                        <Stack direction='row' className={Style.menu} sx={{ flexGrow: 0.04 }}>
                            {/* <Button variant="contained" color='primary' component="label" size='small' endIcon={<CloudDownloadIcon />}>Download Invoice<input hidden accept="image/*" multiple type="file" /></Button> */}
                            <Tooltip title="Documentation" arrow>
                                <IconButton onClick={() => navigate("/doc")} ><InfoIcon /></IconButton>
                            </Tooltip>
                            <Tooltip title="Profile Details" arrow>
                                <IconButton onClick={() => navigate("/profile")} ><ContactsIcon /></IconButton>
                            </Tooltip>
                            <IconButton
                                onClick={(event) => {
                                    setMenuVisible(!meunVisible)
                                    setMenuAnchorEl(event.currentTarget);
                                }}>
                                <Avatar src={profileData?.profile_img} sx={{ fontSize: 14 }} id='user_menu'>{profileData?.publisher_name?.charAt(0)}</Avatar>
                            </IconButton>
                        </Stack>
                    </Box>
                </Box>
            </Box>


            {/* user section menu */}
            <Menu open={meunVisible} anchorEl={menuAnchorEl} onClose={menuHandle}>
                <Box sx={{ p: 2, width: 200 }}>
                    <Typography sx={{ mb: 1, }} variant='subtitle2'>👋  Hey,<strong>{activeUserName}</strong></Typography>
                </Box>
                <Divider />
                <MenuItem onClick={() => navigate("/profile")}>
                    <ListItemIcon>
                        <PersonIcon fontSize="small" color='primary' />
                    </ListItemIcon>
                    Profile
                </MenuItem>

                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small" color='primary' />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>


            <SnackUI state={snack} setState={setSnack} status={status} message={message} />
        </>
    )
}

NavbarUI.propTypes = {
    breadcrumb: PropTypes.any
};