import { createTheme } from '@mui/material/styles';

export const themeDesign = createTheme({
    typography: {
        allVariants: {
            fontFamily: `'Be Vietnam Pro', sans-serif`
        },
    },
    palette: {
        primary: { main: '#1F90E4' },
    },
    shape: {
        borderRadius: 10,
    },

    components: {
        MuiListItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: '#1F90E4',
                        color: '#1F90E4',
                    },
                },
            },
        },
    },
})